import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, Menu, Row, Space } from 'antd'
import { DatabaseOutlined } from '@ant-design/icons'

interface IGranularityFilter {
  timeDimensions: any
  updateTimeDimension: any
}

const GranularityFilter: React.FC<IGranularityFilter> = ({
  timeDimensions,
  updateTimeDimension
}) => {
  const { t } = useTranslation()

  if (!timeDimensions || !timeDimensions.length) {
    return <></>
  }
  let granularities = timeDimensions[0].dimension.granularities
  let filteredGranularities =
    granularities &&
    granularities.filter((item) => {
      return item.name !== 'second' && item.name !== 'minute'
    }) // hide granularity second and minute
  return (
    <Row>
      <Space direction="horizontal">
        <DatabaseOutlined />
        {t(`solutions.scenes.overview.add.data.aggregation`)}
        <Dropdown
          overlay={
            <Menu>
              {filteredGranularities &&
                filteredGranularities.map((granularity) => (
                  <Menu.Item
                    key={granularity.title}
                    onClick={() =>
                      timeDimensions.forEach((timeDimension) => {
                        updateTimeDimension.update(timeDimension, {
                          ...timeDimension,
                          granularity: granularity.name
                        })
                      })
                    }
                  >
                    {t(
                      'solutions.scenes.overview.add.data.timeAggregations.' +
                        granularity.title
                    )}
                  </Menu.Item>
                ))}
            </Menu>
          }
          placement="bottomLeft"
          trigger={['click']}
        >
          <Button>
            {t(
              'solutions.scenes.overview.add.data.timeAggregations.' +
                granularities.find((item) => {
                  return item.name === timeDimensions[0].granularity
                }).title
            )}
          </Button>
        </Dropdown>
      </Space>
    </Row>
  )
}

export default GranularityFilter
