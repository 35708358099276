import React, { useEffect } from 'react'
import moment from 'moment'
import { Calendar, momentLocalizer, SlotInfo, Event } from 'react-big-calendar'
import './BoxScheduleCalendar.scss'
import { useDispatch } from 'react-redux'
import { callSaveBoxActionSchedule } from '../../redux/actions/boxActionSchedule'
import {
  EBoxScheduleActionType,
  IBoxScheduleAction
} from '../../types/boxScheduleAction'
import {
  Button,
  Col,
  Row,
  Space,
  DatePicker,
  Divider,
  Radio,
  Alert,
  Tooltip,
  List,
  Switch
} from 'antd'
import LoadingAnimation from '../LoadingAnimation'
import { DeleteOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from '../ConfirmDialog/ConfirmationDialog'
import FormLabelWithToolTip from '../FormLabelWithToolTip'
import Scrollbars from 'react-custom-scrollbars-2'
import { useBoxActionScheduleState } from '../../helpers/hooks/useBoxActionSchedule'
import CopyPaste from '../CopyPaste/CopyPaste'
import { ECopyPasteType } from '../../types/copyPaste'

type BoxScheduleCalendarProps = {
  boxId: string
}

const BoxScheduleCalendar: React.FC<BoxScheduleCalendarProps> = ({ boxId }) => {
  const [selectedSlot, setSelectedSlot] = React.useState<SlotInfo | null>(null)
  const [isDeleteMode, setIsDeleteMode] = React.useState<boolean>(false)
  const [scheduleCache, setScheduleCache] = React.useState<Event[][]>([])
  const [newRangePicker, setNewRangePicker] = React.useState<boolean>(false)
  const [activeEvents, setActiveEvents] = React.useState<Event[]>([])
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(
    false
  )
  const [boxScheduleEnabled, setBoxScheduleEnabled] = React.useState<boolean>(
    false
  )
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const localizer = momentLocalizer(moment)

  const {
    boxActionSchedule,
    loading: actionScheduleLoading,
    error: actionScheduleLoadingError
  } = useBoxActionScheduleState(boxId)

  useEffect(() => {
    // map boxActionSchedule to activeEvents by sorting by timestamp and then pairing "wakeup" and "standby" types to an active event
    let newActiveEvents: Event[] = []
    if (
      actionScheduleLoading ||
      actionScheduleLoadingError ||
      !boxActionSchedule
    ) {
      setActiveEvents(newActiveEvents)
      return
    }
    let sortedEvents = [...boxActionSchedule.schedule].sort((a, b) => {
      return moment(a.scheduledTime).diff(b.scheduledTime)
    })
    let activeTimestamp: moment.Moment | undefined = undefined

    for (const scheduledAction of sortedEvents) {
      if (scheduledAction.action === EBoxScheduleActionType.wakeup) {
        activeTimestamp = moment(scheduledAction.scheduledTime)
      } else if (scheduledAction.action === EBoxScheduleActionType.standby) {
        if (activeTimestamp) {
          newActiveEvents.push({
            start: activeTimestamp.toDate(),
            end: moment(scheduledAction.scheduledTime).toDate(),
            title: t('configuration.boxScheduleCalendar.active')
          })
          activeTimestamp = undefined
        }
      }
    }
    setBoxScheduleEnabled(!!boxActionSchedule.enabled)
    setActiveEvents(newActiveEvents)
    setScheduleCache([])
  }, [boxActionSchedule, actionScheduleLoadingError, actionScheduleLoading, t])

  const saveSchedule = () => {
    let boxScheduleActions: IBoxScheduleAction[] = []

    activeEvents.forEach((event) => {
      if (event.title === t('configuration.boxScheduleCalendar.active')) {
        boxScheduleActions.push({
          action: EBoxScheduleActionType.wakeup,
          scheduledTime: event.start!.toISOString()
        })
        boxScheduleActions.push({
          action: EBoxScheduleActionType.standby,
          scheduledTime: event.end!.toISOString()
        })
      }
    })
    dispatch(
      callSaveBoxActionSchedule(boxId, {
        ...boxActionSchedule,
        schedule: boxScheduleActions,
        enabled: boxScheduleEnabled
      })
    )
    setScheduleCache([])
  }

  const onDateChangeRangePicker = (index: number) => (dates) => {
    if (!dates) {
      //delete Event
      let newActiveEvents = activeEvents.filter((activeEvent, i) => i !== index)
      setNewRangePicker(false)
      setScheduleCache(scheduleCache.slice(-5).concat([activeEvents]))
      setActiveEvents(newActiveEvents)
    } else if (dates[0] && dates[1] && dates[0] < dates[1]) {
      // update Event
      let newEvent = {
        ...activeEvents[index],
        start: dates[0].toDate(),
        end: dates[1].toDate()
      }
      let newActiveEvents = sortAndMergeEvents(
        newEvent,
        activeEvents.filter(
          (event) =>
            !moment(event.start).isSame(dates[0]) &&
            !moment(event.end).isSame(dates[1])
        )
      )
      setScheduleCache(scheduleCache.slice(-5).concat([activeEvents]))
      setActiveEvents(newActiveEvents)
    }
  }

  const onNewDateChangeRangePicker = () => (dates) => {
    if (dates && dates[0] && dates[1] && dates[0] < dates[1]) {
      let newEvent = {
        start: dates[0].toDate(),
        end: dates[1].toDate(),
        title: t('configuration.boxScheduleCalendar.active')
      }
      let newActiveEvents = sortAndMergeEvents(newEvent)
      setScheduleCache(scheduleCache.slice(-5).concat([activeEvents]))
      setActiveEvents(newActiveEvents)
      setNewRangePicker(false)
    }
  }

  function sortAndMergeEvents(newEvent: Event, oldEvents = activeEvents) {
    let sortedEvents = [...oldEvents, newEvent].sort((a, b) => {
      return moment(a.start).diff(b.start)
    })

    // merge events where end of first event overlaps start of second event
    let mergedEvents: Event[] = [{ ...sortedEvents[0] }]
    for (let i = 1; i < sortedEvents.length; i++) {
      let lastEventInMerged = mergedEvents[mergedEvents.length - 1]
      if (moment(sortedEvents[i].start).isSameOrBefore(lastEventInMerged.end)) {
        lastEventInMerged.end = moment
          .max(moment(sortedEvents[i].end), moment(lastEventInMerged.end))
          .toDate()
      } else {
        // If the current event doesn't overlap, add it to the merged array
        mergedEvents.push({ ...sortedEvents[i] })
      }
    }
    return mergedEvents
  }

  const onSelectSlot = (slotInfo) => {
    if (isDeleteMode) {
      return
    }

    setNewRangePicker(false)

    if (selectedSlot && selectedSlot.start <= slotInfo.start) {
      let newEvent = {
        start: selectedSlot.start,
        end: slotInfo.end,
        title: t('configuration.boxScheduleCalendar.active')
      }
      let mergedEvents = sortAndMergeEvents(newEvent)
      setScheduleCache(scheduleCache.slice(-5).concat([activeEvents]))
      setActiveEvents(mergedEvents)

      setSelectedSlot(null)
    } else {
      setSelectedSlot(slotInfo)
    }
  }

  if (actionScheduleLoading) {
    return <LoadingAnimation />
  }
  return (
    <Scrollbars style={{ marginBottom: '20px' }}>
      <Row gutter={20} style={{ width: '99%' }}>
        <Col span={8}>
          <FormLabelWithToolTip
            id={'configuration.boxScheduleCalendar.activate'}
          />
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Switch
              checked={boxScheduleEnabled}
              checkedChildren={t('draw.toggle.enabled')}
              unCheckedChildren={t('draw.toggle.disabled')}
              onClick={setBoxScheduleEnabled}
            />
          </Space>
          <Divider />
          <FormLabelWithToolTip
            id={'configuration.boxScheduleCalendar.actions'}
          />
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Radio.Group
              onChange={(e) => {
                setIsDeleteMode(e.target.value)
                setSelectedSlot(null)
              }}
              value={isDeleteMode}
              optionType="button"
              options={[
                {
                  label: t('configuration.boxScheduleCalendar.actions.add'),
                  value: false
                },
                {
                  label: t('configuration.boxScheduleCalendar.actions.remove'),
                  value: true
                }
              ]}
            ></Radio.Group>
            <Tooltip
              title={t('configuration.boxScheduleCalendar.actions.undo')}
            >
              <Button
                disabled={scheduleCache.length === 0}
                onClick={() =>
                  // undo last change
                  {
                    if (scheduleCache.length > 0) {
                      setActiveEvents(scheduleCache[scheduleCache.length - 1])
                      setScheduleCache(scheduleCache.slice(0, -1))
                    }
                  }
                }
              >
                <UndoOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              title={t('configuration.boxScheduleCalendar.actions.clear')}
            >
              <Button
                disabled={activeEvents.length === 0}
                onClick={() => {
                  setScheduleCache(
                    scheduleCache.slice(-5).concat([activeEvents])
                  )
                  setActiveEvents([])
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Space>
          <Divider />
          <FormLabelWithToolTip
            id={'configuration.boxScheduleCalendar.listView'}
          />
          <List
            locale={{
              emptyText: t(
                'configuration.boxScheduleCalendar.listView.emptyText'
              )
            }}
            dataSource={activeEvents}
            renderItem={(event, index) => (
              <DatePicker.RangePicker
                className="scc--box-schedule-range-picker"
                key={
                  event.start!.toISOString() +
                  event.end!.toISOString() +
                  event.title
                }
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                defaultValue={[moment(event.start), moment(event.end)]}
                onCalendarChange={onDateChangeRangePicker(index)}
              />
            )}
            split={false}
            pagination={{ pageSize: 7, hideOnSinglePage: true }}
          />
          {newRangePicker ? (
            <DatePicker.RangePicker
              className="scc--box-schedule-range-picker"
              key={'newschedulepicker'}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              onCalendarChange={onNewDateChangeRangePicker()}
            />
          ) : (
            <Button
              className="scc--box-schedule-range-picker"
              onClick={() => {
                setNewRangePicker(true)
                setSelectedSlot(null)
              }}
            >
              <PlusOutlined />
            </Button>
          )}
          <CopyPaste
            copyType={ECopyPasteType.BOX_SCHEDULE}
            copyData={activeEvents}
            id={'configuration.boxScheduleCalendar.copyPaste'}
            onPasteCallback={async (
              parsedType: ECopyPasteType,
              parsedEvents: Array<any>
            ) => {
              try {
                if (
                  parsedType === ECopyPasteType.BOX_SCHEDULE &&
                  Array.isArray(parsedEvents) &&
                  parsedEvents.every(
                    (event) =>
                      'start' in event && 'end' in event && 'title' in event
                  )
                ) {
                  setScheduleCache(
                    scheduleCache.slice(-5).concat([activeEvents])
                  )
                  setActiveEvents(
                    parsedEvents.map((event) => {
                      return {
                        ...event,
                        start: moment(event.start).toDate(),
                        end: moment(event.end).toDate()
                      }
                    })
                  )
                  return
                }
              } catch (error) {
                throw new Error('Failed to handle callback')
              }
            }}
          />
          <Divider />
          <Alert
            className="scc--box-schedule-alert"
            message={t('configuration.boxScheduleCalendar.saveAlert')}
            type="info"
          />
          <Alert
            className="scc--box-schedule-alert"
            message={t('configuration.boxScheduleCalendar.utcWarning')}
            type="warning"
          />
          <Button type="primary" onClick={() => setConfirmDialogOpen(true)}>
            {t('configuration.boxScheduleCalendar.save')}
          </Button>
          <ConfirmationDialog
            primaryButtonText={t(
              'configuration.boxScheduleCalendar.confirmation.confirm'
            )}
            secondaryButtonText={t(
              'configuration.boxScheduleCalendar.confirmation.cancel'
            )}
            onRequestSubmit={() => {
              saveSchedule()
              setConfirmDialogOpen(false)
            }}
            onRequestClose={() => setConfirmDialogOpen(false)}
            size="sm"
            open={confirmDialogOpen}
          >
            {t('configuration.boxScheduleCalendar.confirmation.text')}
          </ConfirmationDialog>
        </Col>
        <Col span={16}>
          <Calendar
            className={
              isDeleteMode
                ? 'scc--box-schedule-calendar-delete'
                : 'scc--box-schedule-calendar'
            }
            localizer={localizer}
            events={
              selectedSlot
                ? [
                    ...activeEvents,
                    {
                      start: selectedSlot.start,
                      end: selectedSlot.end,
                      title: 'Start'
                    }
                  ]
                : activeEvents
            }
            showMultiDayTimes={true}
            messages={{
              week: t(
                'configuration.boxScheduleCalendar.calendarTranslations.week'
              ),
              next: t(
                'configuration.boxScheduleCalendar.calendarTranslations.next'
              ),
              previous: t(
                'configuration.boxScheduleCalendar.calendarTranslations.previous'
              ),
              today: t(
                'configuration.boxScheduleCalendar.calendarTranslations.today'
              )
            }}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(event, start, end, isSelected) => {
              if (
                event.title === t('configuration.boxScheduleCalendar.active')
              ) {
                if (isDeleteMode) {
                  // red background on hover
                  return {
                    className: 'deleteModeEvent boxScheduleActiveEvent'
                  }
                }
                return {
                  className: boxScheduleEnabled
                    ? 'boxScheduleActiveEvent'
                    : 'boxScheduleDisabledActiveEvent'
                }
              } else {
                return { className: 'selectionModeEvent' }
              }
            }}
            style={{ maxHeight: '2000px', width: '100%', marginBottom: '20px' }}
            selectable={true}
            defaultView={'week'}
            step={15}
            timeslots={4}
            views={['week']}
            formats={{
              timeGutterFormat: 'HH:mm',
              eventTimeRangeFormat: ({ start, end }) =>
                `${moment(start).format('HH:mm')} - ${moment(end).format(
                  'HH:mm'
                )}`,
              eventTimeRangeStartFormat: ({ start }) =>
                `${moment(start).format('HH:mm')} ->`,
              eventTimeRangeEndFormat: ({ end }) =>
                `<- ${moment(end).format('HH:mm')}`,
              dayFormat: 'MMM DD YYYY'
            }}
            onSelectSlot={onSelectSlot}
            onSelecting={() => {
              return false
            }}
            onSelectEvent={(event) => {
              if (isDeleteMode) {
                let newActiveEvents = activeEvents.filter(
                  (activeEvent) => activeEvent.start !== event.start
                )
                setScheduleCache(scheduleCache.slice(-5).concat([activeEvents]))
                setActiveEvents(newActiveEvents)
              }
            }}
          />
        </Col>
      </Row>
    </Scrollbars>
  )
}

export default BoxScheduleCalendar
