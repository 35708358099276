import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import { isFocusArea } from '../../types/regionOfInterest'
import { Button, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ShowHideToggleIcon from '../../helpers/ShowHideToggleIcon'

interface IFocusAreaTable {
  eventTriggers: ICoordinateBasedEventTrigger[]
  selectedEventTrigger: string
  onClickRow: (IEventTrigger) => void
  onDelete: (IEventTrigger) => void
  onToggleHideEventTrigger: (trigger: ICoordinateBasedEventTrigger) => void
  hiddenEventTriggers: ICoordinateBasedEventTrigger[]
}

const FocusAreaTable: React.FC<IFocusAreaTable> = ({
  eventTriggers,
  selectedEventTrigger,
  onClickRow,
  onDelete,
  children,
  hiddenEventTriggers,
  onToggleHideEventTrigger
}) => {
  const { t } = useTranslation()
  eventTriggers = eventTriggers.filter((e) =>
    [EEventTriggerType.regionOfInterest].includes(e.objectType)
  )
  const columns = [
    {
      key: 'uuid',
      title: t('draw.focusTable.headers.uuid'),
      dataIndex: 'uuid'
    },
    {
      key: 'action',
      title: t('draw.focusTable.headers.action'),
      dataIndex: 'action',
      width: '15%'
    }
  ]

  if (process.env.NODE_ENV === 'development') {
    columns.splice(1, 0, {
      key: 'coords',
      title: t('draw.focusTable.headers.coordinates'),
      dataIndex: 'debug'
    })
  }

  let hasData = false
  let idx = 0
  const dataSource: any = []
  eventTriggers.forEach((eventTrigger) => {
    idx += 1
    if (isFocusArea(eventTrigger)) {
      hasData = true
    } else {
      return
    }
    dataSource.push({
      key: eventTrigger.localId,
      typeId: eventTrigger.typeShort,
      uuid: (
        <>
          <span className="scc--boxcontextual--triggerId">
            Focus Area {idx}
          </span>
        </>
      ),
      action: (
        <>
          <Button
            className="scc--table--action"
            title={
              hiddenEventTriggers.some(
                (e) => e.localId === eventTrigger.localId
              )
                ? t('draw.dataTable.actions.show')
                : t('draw.dataTable.actions.hide')
            }
            onClick={(event) => {
              event.stopPropagation()
              onToggleHideEventTrigger(eventTrigger)
            }}
            icon={
              <ShowHideToggleIcon
                isCurrentlyShown={hiddenEventTriggers.some(
                  (e) => e.localId === eventTrigger.localId
                )}
              />
            }
          />
          <Button
            icon={<DeleteOutlined />}
            className="scc--table--action"
            title={t('draw.dataTable.actions.delete')}
            onClick={(event) => {
              event.stopPropagation()
              onDelete(eventTrigger)
            }}
          />
        </>
      ),
      debug: (
        <>
          {eventTrigger.coordinates.map((c, i) => {
            return (
              <div className="scc--centered" key={i}>
                <div>
                  x{i + 1}: {c.x.toFixed(3)}
                </div>
                <div>
                  y{i + 1}: {c.y.toFixed(3)}
                </div>
              </div>
            )
          })}
        </>
      )
    })
  })

  if (!hasData) {
    return <></>
  }
  return (
    <>
      <div className="scc--boxcontextual--table">
        <Table
          dataSource={dataSource}
          columns={columns}
          rowClassName={(record, index) =>
            record.key === selectedEventTrigger
              ? 'scc--tablerow scc--selected'
              : 'scc--tablerow'
          }
          onRow={(row) => ({
            onClick: () => {
              const selectedTrigger = eventTriggers.find((eventTrigger) => {
                return eventTrigger.localId === row.key
              })
              if (!selectedTrigger) {
                return
              }
              onClickRow(selectedTrigger)
            }
          })}
        />
      </div>
      {children}
    </>
  )
}

export default FocusAreaTable
