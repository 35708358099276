import React from 'react'

import './DrawContextTable.scss'

import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'

import { useTranslation } from 'react-i18next'
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import { Badge, Button, Space, Table } from 'antd'
import { ITriggerRule } from '../../types/triggerRule'
import { isFocusArea } from '../../types/regionOfInterest'
import ShowHideToggleIcon from '../../helpers/ShowHideToggleIcon'

interface IDrawContextTableProps {
  eventTriggers: ICoordinateBasedEventTrigger[]
  rules: ITriggerRule[]
  selectedEventTrigger: string
  onClickRow: (IEventTrigger) => void
  onDelete: (IEventTrigger) => void
  emptyTableText: string
  hiddenEventTriggers: ICoordinateBasedEventTrigger[]
  onToggleHideEventTrigger: (trigger: ICoordinateBasedEventTrigger) => void
}

const DrawContextTable: React.FC<IDrawContextTableProps> = ({
  eventTriggers,
  rules,
  selectedEventTrigger,
  onClickRow,
  onDelete,
  children,
  emptyTableText,
  hiddenEventTriggers,
  onToggleHideEventTrigger
}) => {
  const { t } = useTranslation()
  eventTriggers = eventTriggers.filter((e) =>
    [
      EEventTriggerType.crossingLine,
      EEventTriggerType.regionOfInterest,
      EEventTriggerType.virtualDoor,
      EEventTriggerType.originDestinationZone
    ].includes(e.objectType)
  )

  let isSingleRuleBased = (eventTrigger) => {
    return rules.find((rule) => {
      // if no trigger is set it is a global rule which applies to all triggers of the specified type
      return (
        rule.conditions.length === 1 &&
        rule.conditions.some((condition) =>
          condition.trigger
            ? condition.trigger === eventTrigger.localId
            : condition.triggerType === eventTrigger.objectType
        )
      )
    })
  }

  let isCombinedRuleBased = (eventTrigger) => {
    return rules.find((rule) => {
      // if no trigger is set it is a global rule which applies to all triggers of the specified type
      return (
        rule.conditions.length > 1 &&
        rule.conditions.some((condition) =>
          condition.trigger
            ? condition.trigger === eventTrigger.localId
            : condition.triggerType === eventTrigger.objectType
        )
      )
    })
  }

  const columns = [
    {
      key: 'name',
      title: t('draw.dataTable.headers.name'),
      dataIndex: 'name',
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      key: 'type',
      title: t('draw.dataTable.headers.type'),
      dataIndex: 'type',
      filters: [
        {
          text: t('draw.icon.cl'),
          value: 'cl'
        },
        {
          text: t('draw.icon.roi'),
          value: 'roi'
        },
        {
          text: t('draw.icon.od'),
          value: 'od'
        },
        {
          text: t('draw.icon.vd'),
          value: 'vd'
        }
      ],
      onFilter: (value, record) => record.typeId.toLowerCase() === value,
      width: '10%'
    },
    { key: 'uuid', title: t('draw.dataTable.headers.uuid'), dataIndex: 'uuid' },
    {
      key: 'action',
      title: t('draw.dataTable.headers.action'),
      dataIndex: 'action',
      width: '15%'
    }
  ]

  if (process.env.NODE_ENV === 'development') {
    columns.splice(1, 0, {
      key: 'coords',
      title: t('draw.dataTable.headers.coordinates'),
      dataIndex: 'debug'
    })
  }

  const dataSource: any = []
  eventTriggers.forEach((eventTrigger) => {
    if (isFocusArea(eventTrigger)) {
      return
    }
    dataSource.push({
      key: eventTrigger.localId,
      typeId: eventTrigger.typeShort,
      name:
        eventTrigger.name ||
        t('draw.dataTable.data.name.undefined') + ' ' + eventTrigger.typeShort,
      type: (
        <Space>
          {EventTriggerIcon({
            eventTrigger,
            t
          })}
          {isSingleRuleBased(eventTrigger) && (
            <Badge
              className="rule-based-badge"
              count={t('draw.ruleEngine.triggerBadge')}
              title={t('draw.ruleEngine.triggerBadgeLabel')}
            />
          )}
          {isCombinedRuleBased(eventTrigger) && (
            <Badge
              className="rule-based-badge"
              count={t('draw.ruleEngine.triggerBadgeCombined')}
              title={t('draw.ruleEngine.triggerBadgeLabel')}
            />
          )}
        </Space>
      ),
      uuid: (
        <>
          {eventTrigger.id && (
            <Button
              icon={<CopyOutlined />}
              className="scc--boxcontextual--table--copyid-button"
              onClick={() => {
                copy(eventTrigger.id)
              }}
            />
          )}
          <span className="scc--boxcontextual--triggerId">
            {eventTrigger.id}
          </span>
        </>
      ),
      action: (
        <>
          <Button
            className="scc--table--action"
            title={
              hiddenEventTriggers.some(
                (e) => e.localId === eventTrigger.localId
              )
                ? t('draw.dataTable.actions.show')
                : t('draw.dataTable.actions.hide')
            }
            onClick={(event) => {
              event.stopPropagation()
              onToggleHideEventTrigger(eventTrigger)
            }}
            icon={
              <ShowHideToggleIcon
                isCurrentlyShown={hiddenEventTriggers.some(
                  (e) => e.localId === eventTrigger.localId
                )}
              />
            }
          />
          <Button
            icon={<EditOutlined />}
            className="scc--table--action"
            title={t('draw.dataTable.actions.edit')}
          />
          <Button
            icon={<DeleteOutlined />}
            className="scc--table--action"
            title={t('draw.dataTable.actions.delete')}
            onClick={(event) => {
              event.stopPropagation()
              onDelete(eventTrigger)
            }}
          />
        </>
      ),
      debug: (
        <>
          {eventTrigger.coordinates.map((c, i) => {
            return (
              <div className="scc--centered" key={i}>
                <div>
                  x{i + 1}: {c.x.toFixed(3)}
                </div>
                <div>
                  y{i + 1}: {c.y.toFixed(3)}
                </div>
              </div>
            )
          })}
        </>
      )
    })
  })

  return (
    <>
      <div className="scc--boxcontextual--table">
        <Table
          dataSource={dataSource}
          columns={columns}
          rowClassName={(record, index) =>
            record.key === selectedEventTrigger
              ? 'scc--tablerow scc--selected'
              : 'scc--tablerow'
          }
          onRow={(row) => ({
            onClick: () => {
              const selectedTrigger = eventTriggers.find((eventTrigger) => {
                return eventTrigger.localId === row.key
              })
              if (!selectedTrigger) {
                return
              }
              onClickRow(selectedTrigger)
            }
          })}
          locale={{
            emptyText: emptyTableText
          }}
        />
      </div>
      {children}
    </>
  )
}

const EventTriggerIcon = ({ eventTrigger, t }) => {
  const type = eventTrigger.typeShort

  return (
    <i
      className={`scc--icon scc--icon--${type.toLowerCase()}`}
      title={t(`draw.icon.${type.toLowerCase()}`)}
    >
      {type}
    </i>
  )
}

export default DrawContextTable
