export enum ECopyPasteType {
  BOX_SCHEDULE = 'boxSchedule',
  EVENT_TRIGGER = 'eventTrigger',
  CUSTOM = 'custom'
}

export interface ICopyPaste {
  type: ECopyPasteType
  data: string | null
}
