export interface IGroup {
  id: string
  name: string
  new?: boolean
}

export const DefaultGroupId = 'default'

export const DefaultGroup = (t: (key: string) => string): IGroup => {
  return {
    id: DefaultGroupId,
    name: t('solutions.groups.default')
  }
}
