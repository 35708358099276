import '../components/ConfirmDialog/ConfirmationDialog.scss'
import { NavLink, Prompt, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import {
  addBoxEventTrigger,
  deleteStreamEventTrigger,
  loadStreamEventTriggers,
  resetStreamEventTriggers,
  saveStreamEventTriggers,
  saveStreamRoiInterval,
  setHighlightedStreamEventTrigger,
  setSelectedStreamEventTrigger,
  updateStreamEventTrigger
} from '../redux/actions/eventTriggers'
import { FormGroup } from 'carbon-components-react'
import FormLabelWithTooltip from '../components/FormLabelWithToolTip'
import { CloseOutline24 } from '@carbon/icons-react'
import ConfirmationDialog from '../components/ConfirmDialog/ConfirmationDialog'
import { DeleteConfirmationDialog } from '../components/ConfirmDialog/DeleteConfirmationDialog'
import DrawCanvas from '../components/DrawCanvas'
import DrawContextForm from '../components/DrawContextForm'
import DrawContextTable from '../components/DrawContextTable'
import FocusAreaTable from '../components/FocusAreaTable'
import RuleEngine from '../components/RuleEngine'
import { EBoxModel } from '../types/boxModel'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../types/eventTrigger'
import { HeatMapEventTrigger } from '../types/heatMap'
import { AnprEventTrigger } from '../types/anpr'
import { connect } from 'react-redux'
import { loadCameraFrame, resetCameraFrame } from '../redux/actions/cameraFrame'
import { notify } from '../services/notify'
import { showErrorMessage } from '../redux/actions/error'
import { withTranslation } from 'react-i18next'
import { loadBoxStreams, saveStream } from '../redux/actions/streams'
import {
  DeviceType,
  EBoxUpdateStatus,
  IBox,
  NVIDIA_DEVICES
} from '../types/box'
import { loadBoxes } from '../redux/actions/boxes'
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons'
import { CrossingLineEventTrigger } from '../types/crossingLine'
import { SpeedEstimationTrigger } from '../types/speedEstimation'
import {
  ERegionOfInterestType,
  isFocusArea,
  RegionOfInterestEventTrigger
} from '../types/regionOfInterest'
import {
  deleteStreamEventRule,
  loadStreamEventRules,
  loadStreamEventRulesTemplates,
  saveStreamEventRule,
  saveStreamEventRules
} from '../redux/actions/eventRules'
import Scrollbars from 'react-custom-scrollbars-2'
import { JourneyTimeEventTrigger } from '../types/journeyTime'
import { ICoordinates, IStream, IStreamDetails } from '../types/stream'
import { ECameraFrameMode } from '../types/cameraFrame'
import { Button, Col, Divider, Row, Tag, Tooltip } from 'antd'
import { ERuntimeState } from '../types/runtimeState'
import { EErrorReason } from '../types/errorReason'
import OperationalStatusCell from '../components/BoxList/cells/OperationalStatusCell'
import { getIdComponent } from '../components/HelperComponents'
import {
  loadStreamDetails,
  saveStreamDetails
} from '../redux/actions/streamDetails'
import { EMqttConfigurationType } from '../types/eventdataOutputConfiguration'
import copy from 'copy-to-clipboard'
import { clipLongText } from '../components/BoxDetailsHeader/MetadataTable'
import { useBoxActionScheduleState } from '../helpers/hooks/useBoxActionSchedule'
import { GdprEventTrigger } from '../types/gdpr'

import CopyPaste from '../components/CopyPaste/CopyPaste'
import { ECopyPasteType } from '../types/copyPaste'

const uuid = require('lodash-uuid')

interface IStreamContextualPageState {
  isSaveConfirmationDialogVisible: boolean
  isDeleteConfirmationDialogVisible: boolean
  isInvalidModelErrorVisible: boolean
  eventTriggerToDelete: ICoordinateBasedEventTrigger | null
  selectedModel: EBoxModel | null
  hiddenEventTriggers: ICoordinateBasedEventTrigger[]

  attachLicensePlateImage: boolean
  hasCustomMqtt: boolean
  coordinates: ICoordinates | undefined
  newRoiInterval: { [value: string]: number }
  deviceType: DeviceType
  showDeleteRuleWarning: boolean
  showDeleteAllWarning: boolean
  calibration: ECameraFrameMode
}

class StreamContextualPage extends Component<any, IStreamContextualPageState> {
  constructor(props) {
    super(props)

    this.state = {
      isSaveConfirmationDialogVisible: false,
      isDeleteConfirmationDialogVisible: false,
      isInvalidModelErrorVisible: false,
      eventTriggerToDelete: null,
      selectedModel: this.props.selectedModel,
      coordinates: this.props.coordinates,
      newRoiInterval: this.props.roiInterval,
      deviceType: this.props.deviceType,
      showDeleteRuleWarning: false,
      showDeleteAllWarning: false,
      calibration: ECameraFrameMode.default,
      attachLicensePlateImage:
        this.props.streamDetails &&
        this.props.streamDetails.mqttConfig.some(
          (config) =>
            config.configurationType === EMqttConfigurationType.custom &&
            config.attachLicensePlateImage
        ),
      hasCustomMqtt:
        this.props.streamDetails &&
        this.props.streamDetails.mqttConfig.some(
          (config) => config.configurationType === EMqttConfigurationType.custom
        ),
      hiddenEventTriggers: []
    }

    this.onButtonClick = this.onButtonClick.bind(this)
    this.onToggleDirection = this.onToggleDirection.bind(this)
    this.onToggleSpeedEstimate = this.onToggleSpeedEstimate.bind(this)
    this.onValueChanged = this.onValueChanged.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onSelectEventTrigger = this.onSelectEventTrigger.bind(this)
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this)
    this.onDeleteAllButtonClick = this.onDeleteAllButtonClick.bind(this)
    this.onSaveStreamConfig = this.onSaveStreamConfig.bind(this)
    this.onCloseDialog = this.onCloseDialog.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.warnUnsavedChanges = this.warnUnsavedChanges.bind(this)
    this.rerender = this.rerender.bind(this)
    this.onToggleAttachLicensePlateImage = this.onToggleAttachLicensePlateImage.bind(
      this
    )
    this.toggleHiddenEventTrigger = this.toggleHiddenEventTrigger.bind(this)
    this.toggleAllHiddenEventTriggers = this.toggleAllHiddenEventTriggers.bind(
      this
    )
    this.onPasteEventTrigger = this.onPasteEventTrigger.bind(this)
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.warnUnsavedChanges)
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    // Prevent loading same data twice
    if (
      prevProps.match.params.id !== this.props.match.params.id ||
      prevProps.match.params.streamId !== this.props.match.params.streamId
    ) {
      this.loadData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.warnUnsavedChanges)
  }

  // stop and warn user for unsaved changed before leaving the page
  warnUnsavedChanges(e) {
    if (this.hasDataChanged) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  loadData(options?: { loadCameraFrame: boolean }) {
    const { id, streamId } = this.props.match.params

    // Merge default values with provided options
    const config = Object.assign(
      {},
      {
        loadCameraFrame: true
      },
      options
    )

    if (this.props.deviceType === DeviceType.UNSPECIFIED) {
      this.props.loadBoxes()
    }
    this.props.loadStreamDetails(id, streamId).then((streamDetails) => {
      this.setState({
        attachLicensePlateImage: streamDetails.payload.mqttConfig.some(
          (config) =>
            config.configurationType === EMqttConfigurationType.custom &&
            config.attachLicensePlateImage
        ),
        hasCustomMqtt: streamDetails.payload.mqttConfig.some(
          (config) => config.configurationType === EMqttConfigurationType.custom
        ),
        hiddenEventTriggers: []
      })
    })
    this.props.loadBoxStreams(id).then((streams) => {
      const currentstream = streams.response.entities.streams[streamId]
      let selectedModel = currentstream.model
      let currentCoordinates = currentstream.coordinates

      this.setState({
        selectedModel: selectedModel
      })
      this.setState({ coordinates: currentCoordinates })
    })
    this.props.loadStreamEventTriggers(id, streamId).then((data) => {
      let roiInterval = data[streamId].roiInterval
      roiInterval &&
        this.setState({
          newRoiInterval: roiInterval
        })
    })
    this.props.loadStreamEventRules(id, streamId)
    this.props.loadStreamEventRulesTemplates()
    if (config.loadCameraFrame) {
      this.props.loadCameraFrame(id, streamId, false, this.state.calibration)
    }
  }

  onButtonClick(event, type: EEventTriggerType, ...props) {
    event.preventDefault()
    this.props.addBoxEventTrigger(type, props.length === 1 ? props[0] : props)
  }

  onToggleDirection(event, eventTrigger) {
    if (eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled) {
      // make copy of speedtrigger the hold coordinates while swapping
      let speedTriggerObject = new SpeedEstimationTrigger(
        eventTrigger.speedEstimation
      )
      speedTriggerObject.coordinates = eventTrigger.coordinates
      if (eventTrigger.direction === eventTrigger.speedEstimation.direction) {
        eventTrigger.speedEstimation.coordinates.reverse()
      }

      this.props.updateStreamEventTrigger(eventTrigger, {
        coordinates: eventTrigger.speedEstimation.coordinates,
        speedEstimation: speedTriggerObject
      })
    } else {
      // To change the direction, the order of the points has to be reversed
      this.props.updateStreamEventTrigger(eventTrigger, {
        coordinates: eventTrigger.coordinates.reverse()
      })
    }
  }

  toggleHiddenEventTrigger(trigger: ICoordinateBasedEventTrigger) {
    this.setState((prevState) => {
      const triggerIds = prevState.hiddenEventTriggers.map((t) => t.localId)
      if (triggerIds.includes(trigger.localId)) {
        // If the trigger is already hidden, unhide it
        return {
          hiddenEventTriggers: prevState.hiddenEventTriggers.filter(
            (t) => t.localId !== trigger.localId
          )
        }
      } else {
        // If the trigger is not hidden, hide it
        return {
          hiddenEventTriggers: [...prevState.hiddenEventTriggers, trigger]
        }
      }
    })
  }

  toggleAllHiddenEventTriggers() {
    if (this.state.hiddenEventTriggers.length > 0) {
      // If the array is not empty, empty it
      this.setState({ hiddenEventTriggers: [] })
    } else {
      // If the array is empty, fill it with all event triggers
      this.setState({ hiddenEventTriggers: this.props.eventTriggers })
    }
  }
  onToggleSpeedEstimate(event, eventTrigger, checked) {
    const crossingLineTrigger = eventTrigger as CrossingLineEventTrigger
    let speedEstimation = crossingLineTrigger.speedEstimation
    if (speedEstimation) {
      if (!checked) {
        if (
          angleSmaller180Degree(
            eventTrigger.coordinates,
            speedEstimation.coordinates
          )
        ) {
          eventTrigger.coordinates.reverse()
        }
      }
      speedEstimation.enabled = checked
    } else {
      speedEstimation = SpeedEstimationTrigger.default()
      let { point1x, point1y, point2x, point2y } = getParallelLine(eventTrigger)

      speedEstimation.coordinates = [
        {
          x: Math.min(Math.max(point1x, 0), 1),
          y: Math.min(Math.max(point1y, 0), 1)
        },
        {
          x: Math.min(Math.max(point2x, 0), 1),
          y: Math.min(Math.max(point2y, 0), 1)
        }
      ]
    }
    // switch main- and speedline to stay constant with the direction
    if (
      checked &&
      angleSmaller180Degree(
        eventTrigger.coordinates,
        speedEstimation.coordinates
      )
    ) {
      let tempCoordinates = [
        {
          x: speedEstimation.coordinates[0].x,
          y: speedEstimation.coordinates[0].y
        },
        {
          x: speedEstimation.coordinates[1].x,
          y: speedEstimation.coordinates[1].y
        }
      ]
      if (eventTrigger.direction === speedEstimation.direction) {
        speedEstimation.coordinates.reverse()
      }
      speedEstimation.coordinates = eventTrigger.coordinates
      eventTrigger.coordinates = tempCoordinates
    }
    this.props.updateStreamEventTrigger(eventTrigger, {
      coordinates: eventTrigger.coordinates,
      speedEstimation: speedEstimation
    })
  }

  correctValue(value) {
    if (value < 0) {
      return 0
    } else if (value > 1) {
      return 1
    } else {
      return value
    }
  }

  onDragEnd(event, currentEventTrigger, coordinates) {
    // Sort coordinates for polygons clockwise
    if (
      currentEventTrigger.objectType === EEventTriggerType.regionOfInterest ||
      currentEventTrigger.objectType === EEventTriggerType.virtualDoor ||
      currentEventTrigger.objectType === EEventTriggerType.originDestinationZone
    ) {
      // 1. Determine reference point in the center
      const centerPoint = {
        x:
          coordinates.reduce((initial, point) => initial + point.x, 0) /
          coordinates.length,
        y:
          coordinates.reduce((initial, point) => initial + point.y, 0) /
          coordinates.length
      }

      coordinates = coordinates.sort((a, b) => {
        // 2. Calculate angles between points and reference point
        let aTanA = Math.atan2(a.y - centerPoint.y, a.x - centerPoint.x)
        let aTanB = Math.atan2(b.y - centerPoint.y, b.x - centerPoint.x)

        // 3. Determine sort order
        if (aTanA < aTanB) {
          return -1
        } else if (aTanA > aTanB) {
          return 1
        } else {
          return 0
        }
      })
    }

    // Convert coordinates in relative units
    const canvas = event.currentTarget.parent.canvas

    // Canvas width and height need to be correct by pixel ratio
    const canvasWidth = canvas.width / canvas.pixelRatio
    const canvasHeight = canvas.height / canvas.pixelRatio

    if (
      currentEventTrigger.speedEstimation &&
      currentEventTrigger.speedEstimation.enabled
    ) {
      const relativeMain = coordinates.slice(0, 2).map((coordinate) => ({
        x: this.correctValue(coordinate.x / canvasWidth),
        y: this.correctValue(coordinate.y / canvasHeight)
      }))
      const relativeSpeed = coordinates.slice(2, 4).map((coordinate) => ({
        x: this.correctValue(coordinate.x / canvasWidth),
        y: this.correctValue(coordinate.y / canvasHeight)
      }))
      Object.assign(currentEventTrigger.speedEstimation, {
        coordinates: relativeSpeed
      })
      this.props.updateStreamEventTrigger(currentEventTrigger, {
        coordinates: relativeMain,
        speedEstimation: currentEventTrigger.speedEstimation
      })
    } else {
      const relative = coordinates.map((coordinate) => ({
        x: this.correctValue(coordinate.x / canvasWidth),
        y: this.correctValue(coordinate.y / canvasHeight)
      }))

      this.props.updateStreamEventTrigger(currentEventTrigger, {
        coordinates: relative
      })
    }
  }

  onSelectEventTrigger(eventTrigger: ICoordinateBasedEventTrigger) {
    if (this.props.selectedEventTrigger === eventTrigger.localId) {
      return
    }

    this.props.setSelectedStreamEventTrigger(eventTrigger)
  }

  async onPasteEventTrigger(
    parsedType: ECopyPasteType,
    parsedTrigger: Array<any>
  ) {
    try {
      if (
        parsedType === ECopyPasteType.EVENT_TRIGGER &&
        Array.isArray(parsedTrigger) &&
        parsedTrigger.every((trigger) => 'objectType' in trigger)
      ) {
        parsedTrigger.forEach((trigger) => {
          trigger.id && delete trigger.id
          trigger.localId && delete trigger.localId
          const { objectType: type, ...triggerProps } = trigger
          this.props.addBoxEventTrigger(type, triggerProps)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  getEventTriggerByType(type) {
    return this.props.eventTriggers.find(
      (eventTrigger) => eventTrigger.objectType === type
    )
  }

  getEventTriggerById(id) {
    return this.props.eventTriggers.find(
      (eventTrigger) => eventTrigger.localId === id
    )
  }

  checkValidModelChange(model, eventTriggers) {
    if (
      model !== EBoxModel.headDetectorRetailStandard &&
      model !== EBoxModel.personDetectorDemoStandardFast
    ) {
      return true
    }

    return eventTriggers.every((trigger) => {
      return (
        !(trigger instanceof AnprEventTrigger && trigger.enabled) &&
        !(
          trigger instanceof RegionOfInterestEventTrigger &&
          trigger.roiType === ERegionOfInterestType.parking
        )
      )
    })
  }

  onToggleAttachLicensePlateImage(attach) {
    this.setState({
      attachLicensePlateImage: attach
    })
  }

  onValueChanged(eventTrigger, changes) {
    if (eventTrigger && changes.eventTrigger) {
      if (
        eventTrigger.speedEstimation &&
        changes.eventTrigger.speedEstimation
      ) {
        //assigning the old unchanged values of speed-estimation to the as they get lost otherwise
        Object.keys(eventTrigger.speedEstimation).forEach((key) => {
          if (!(key in changes.eventTrigger.speedEstimation)) {
            changes.eventTrigger.speedEstimation[key] =
              eventTrigger.speedEstimation[key]
          }
        })
      }
      this.props.updateStreamEventTrigger(eventTrigger, changes.eventTrigger)
    }

    if (changes.box) {
      let valid = this.checkValidModelChange(
        changes.box.model,
        this.props.eventTriggers
      )
      if (!valid) {
        this.setState({
          isInvalidModelErrorVisible: true
        })
        return
      }
      this.setState({
        selectedModel: changes.box.model || undefined
      })
    }

    if (changes.coordinates) {
      this.setState({ coordinates: changes.coordinates })
    }

    if (changes.stream) {
      let newValue = parseInt(changes.stream.roiInterval)
      this.setState({
        newRoiInterval: {
          ...this.props.roiInterval,
          value: isNaN(newValue) ? '' : newValue
        }
      })
    }
  }

  onDeleteButtonClick(eventTrigger) {
    this.setState({
      isDeleteConfirmationDialogVisible: true,
      showDeleteAllWarning: false,
      eventTriggerToDelete: eventTrigger,
      showDeleteRuleWarning: this.triggerHasRules(eventTrigger)
    })
  }

  onDeleteAllButtonClick() {
    this.setState({
      isDeleteConfirmationDialogVisible: true,
      showDeleteAllWarning: true,
      showDeleteRuleWarning: false
    })
  }

  onFormSubmit() {
    this.setState({
      isSaveConfirmationDialogVisible: true
    })
  }

  async onSaveStreamConfig() {
    if (this.props.isSubmitting) {
      return
    }

    this.setState({
      isSaveConfirmationDialogVisible: false
    })

    const boxId = this.props.match.params.id
    const streamId = this.props.match.params.streamId
    const {
      saveStreamRoiInterval,
      saveStreamEventTriggers,
      saveStreamEventRules,
      saveStream,
      saveStreamDetails
    } = this.props

    const { selectedModel, newRoiInterval, coordinates } = this.state

    const updatedStream = Object.assign({}, this.props.stream)
    updatedStream.model = selectedModel || undefined
    updatedStream.coordinates = coordinates

    let eventTriggers = this.props.eventTriggers
    let eventRules = this.props.eventRules

    try {
      // The stream model needs to be saved first
      // in order for the backend to know what
      // to do with event triggers.
      if (
        this.props.stream.model !== updatedStream.model ||
        this.props.stream.coordinates !== updatedStream.coordinates
      ) {
        await saveStream(boxId, streamId, updatedStream)
      }
      await saveStreamEventTriggers(boxId, streamId, eventTriggers)
      await saveStreamEventRules(boxId, streamId, eventRules)

      if (
        this.props.streamDetails.mqttConfig.some(
          (config) =>
            config.configurationType === EMqttConfigurationType.custom &&
            config.attachLicensePlateImage !==
              this.state.attachLicensePlateImage
        )
      ) {
        let updatedStreamDetails: IStreamDetails = Object.assign(
          {},
          this.props.streamDetails
        )
        const customConfigIndex = updatedStreamDetails.mqttConfig.findIndex(
          (config) => config.configurationType === EMqttConfigurationType.custom
        )
        updatedStreamDetails.mqttConfig[
          customConfigIndex
        ].attachLicensePlateImage = this.state.attachLicensePlateImage
        await saveStreamDetails(boxId, streamId, updatedStreamDetails)
      }

      this.props.roiInterval !== newRoiInterval &&
        (await saveStreamRoiInterval(boxId, streamId, newRoiInterval))
      this.loadData({ loadCameraFrame: false })

      notify({
        title: this.props.t('notification.eventTriggers.saved.title'),
        message: this.props.t('notification.eventTriggers.saved.message')
      })
    } catch (error) {}
  }

  onCloseDialog(event) {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }

    this.setState({
      isSaveConfirmationDialogVisible: false,
      isDeleteConfirmationDialogVisible: false,
      isInvalidModelErrorVisible: false,
      eventTriggerToDelete: null
    })
  }

  onDeleteTrigger(boxId, streamId, event) {
    if (this.state.eventTriggerToDelete) {
      this.props.deleteStreamEventTrigger(
        boxId,
        streamId,
        this.state.eventTriggerToDelete
      )
      this.onCloseDialog(event)
    }

    const isANPRRelevant = (trigger) => {
      return (
        trigger instanceof CrossingLineEventTrigger ||
        (trigger instanceof RegionOfInterestEventTrigger &&
          trigger.roiType === ERegionOfInterestType.parking)
      )
    }
    const isAnonymizedPlateRelevant = (trigger) => {
      return trigger instanceof CrossingLineEventTrigger
    }

    if (!isANPRRelevant(this.state.eventTriggerToDelete)) {
      return
    }

    // disable ANPR if the last CL and ROI is removed
    let anprConnectedTriggers = this.props.eventTriggers.filter((trigger) => {
      return isANPRRelevant(trigger)
    })
    let anprTrigger = this.props.eventTriggers.find((trigger) => {
      return trigger instanceof AnprEventTrigger
    })
    if (anprConnectedTriggers.length === 1 && anprTrigger.enabled) {
      this.onValueChanged(anprTrigger, {
        eventTrigger: Object.assign({}, anprTrigger, { enabled: false })
      })
      this.props.saveStreamEventTriggers(boxId, streamId, [anprTrigger])
    }

    if (!isAnonymizedPlateRelevant(this.state.eventTriggerToDelete)) {
      return
    }

    // disable JourneyTime if the last CL is removed
    let anonymizedAnprConnectedTrigger = this.props.eventTriggers.filter(
      (trigger) => {
        return trigger instanceof CrossingLineEventTrigger
      }
    )
    let jounreyTimeTrigger = this.props.eventTriggers.find((trigger) => {
      return trigger instanceof JourneyTimeEventTrigger
    })

    let gdprTrigger = this.props.eventTriggers.find((trigger) => {
      return trigger instanceof GdprEventTrigger
    })
    if (
      anonymizedAnprConnectedTrigger.length === 1 &&
      jounreyTimeTrigger.enabled
    ) {
      this.onValueChanged(jounreyTimeTrigger, {
        eventTrigger: Object.assign({}, jounreyTimeTrigger, { enabled: false })
      })
      this.props.saveStreamEventTriggers(boxId, streamId, [jounreyTimeTrigger])
    }
    if (anonymizedAnprConnectedTrigger.length === 1 && gdprTrigger.enabled) {
      this.onValueChanged(gdprTrigger, {
        eventTrigger: Object.assign({}, gdprTrigger, { enabled: false })
      })
      this.props.saveStreamEventTriggers(boxId, streamId, [gdprTrigger])
    }
  }

  onDeleteAll(boxId, streamId, event) {
    this.props.resetStreamEventTriggers(boxId, streamId)
    this.onCloseDialog(event)
    this.loadData({ loadCameraFrame: false })
  }

  get hasDataChanged(): boolean {
    const eventTriggers = this.props.eventTriggers || []
    const eventRules = this.props.eventRules || []
    const stream = this.props.stream
    if (stream !== undefined) {
      const hasStreamBeenUpdated = stream
        ? stream.model !== this.state.selectedModel ||
          stream.coordinates !== this.state.coordinates
        : false

      const haveEventTriggersBeenUpdated = eventTriggers.some(
        (trigger) => trigger.hasChanged
      )
      const haveEventRulesBeenUpdated = eventRules.some(
        (rule) => rule.hasChanged
      )
      const hasRoiIntervalBeenUpdated =
        !this.props.roiInterval ||
        this.props.roiInterval !== this.state.newRoiInterval

      const hasMqttConfigBeenUpdated =
        this.props.streamDetails &&
        this.props.streamDetails.mqttConfig.some(
          (config) =>
            config.configurationType === EMqttConfigurationType.custom &&
            config.attachLicensePlateImage !==
              this.state.attachLicensePlateImage
        )

      return (
        hasStreamBeenUpdated ||
        haveEventTriggersBeenUpdated ||
        haveEventRulesBeenUpdated ||
        hasRoiIntervalBeenUpdated ||
        hasMqttConfigBeenUpdated
      )
    }
    return false
  }

  get emptyTableText(): string {
    return this.props.t('draw.dataTable.data.empty.default')
  }

  rerender() {
    this.forceUpdate()
  }

  triggerHasRules(trigger: ICoordinateBasedEventTrigger | null) {
    return this.props.eventRules.some((rule) => {
      return (
        trigger &&
        (rule.conditions.some((condition) => {
          return condition.trigger === trigger.localId
        }) ||
          // case global od rule
          (trigger.objectType === EEventTriggerType.originDestinationZone &&
            rule.conditions.some(
              (condition) =>
                condition.triggerType ===
                EEventTriggerType.originDestinationZone
            )))
      )
    })
  }

  render() {
    const boxId = this.props.match.params.id
    const streamId = this.props.match.params.streamId
    const heatMapEventTrigger = (this.getEventTriggerByType(
      EEventTriggerType.heatMap
    ) as unknown) as HeatMapEventTrigger

    const anprEventTrigger = (this.getEventTriggerByType(
      EEventTriggerType.anpr
    ) as unknown) as AnprEventTrigger

    const journeyTimeEventTrigger = (this.getEventTriggerByType(
      EEventTriggerType.journeyTime
    ) as unknown) as JourneyTimeEventTrigger

    const gdprEventTrigger = (this.getEventTriggerByType(
      EEventTriggerType.gdpr
    ) as unknown) as GdprEventTrigger

    const {
      t,
      cameraFrame,
      highlightedEventTrigger,
      selectedEventTrigger,
      isSubmitting,
      loadCameraFrame,
      isLoadingCameraFrame,
      deviceType,
      eventTriggers,
      currentBox,
      currentStream
    } = this.props

    let eventRules = this.props.eventRules
    let userTemplates = this.props.userTemplates
    const eventTrigger = this.getEventTriggerById(
      this.props.selectedEventTrigger
    )

    let anprOptionaAvailable = eventTriggers.some((trigger) => {
      return (
        (trigger instanceof RegionOfInterestEventTrigger &&
          trigger.roiType === ERegionOfInterestType.parking) ||
        trigger instanceof CrossingLineEventTrigger
      )
    })

    let anonymisedAnprOptionAvailable = eventTriggers.some((trigger) => {
      return trigger instanceof CrossingLineEventTrigger
    })

    let enableRoiInterval = eventTriggers.some((trigger) => {
      return trigger instanceof RegionOfInterestEventTrigger
    })

    let hasRuleAttached = this.triggerHasRules(eventTrigger)

    let enableFocusArea =
      eventTriggers.filter((eventTrigger) => isFocusArea(eventTrigger)).length <
      4

    return (
      <div
        className={`scc--boxcontextual-container ${
          this.props.sceneId ? 'sceneNote' : ''
        }`}
      >
        <Prompt // stop and warn user for unsaved changed before leaving the page
          when={this.hasDataChanged}
          message={t('modal.configuration.warnUnsavedChanges')}
        />

        <Scrollbars autoHide={true}>
          <div className="bx--grid">
            <div className="scc--boxcontextual-container--sceneNote">
              <NavLink
                to={`/solutions/${this.props.sceneId}#streams`}
                onClick={() => this.props.resetCameraFrame(streamId)}
              >
                <ArrowLeftOutlined />
                &nbsp; {t('modal.configuration.returnSceneLink')}
              </NavLink>
            </div>
            <div className="scc--boxcontextual-container--closeheadline">
              <NavLink to={`/boxes/${boxId}`} className="scc--close-button">
                <CloseOutline24 className="scc--fill-main" />
              </NavLink>
            </div>
            {currentBox && currentStream && (
              <HeaderComponent t={t} stream={currentStream} box={currentBox} />
            )}
            <div className="bx--row">
              <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-4">
                <DrawContextForm
                  heatMap={heatMapEventTrigger}
                  anpr={anprEventTrigger}
                  journeyTime={journeyTimeEventTrigger}
                  gdpr={gdprEventTrigger}
                  shouldShowLPMode={NVIDIA_DEVICES.has(deviceType)}
                  anprOptionaAvailable={anprOptionaAvailable}
                  anonymisedAnprOptionAvailable={anonymisedAnprOptionAvailable}
                  enableRoiInterval={enableRoiInterval}
                  eventTrigger={eventTrigger}
                  hasRuleAttached={hasRuleAttached}
                  onButtonClick={this.onButtonClick}
                  onValueChanged={this.onValueChanged}
                  onToggleDirection={this.onToggleDirection}
                  onToggleSpeedEstimate={this.onToggleSpeedEstimate}
                  model={this.state.selectedModel}
                  coordinates={this.state.coordinates}
                  roiInterval={this.state.newRoiInterval.value}
                  onSubmit={this.onFormSubmit}
                  hasDataChanged={this.hasDataChanged}
                  isSubmitting={isSubmitting}
                  enableFocusArea={enableFocusArea}
                  attachLicensePlateImage={this.state.attachLicensePlateImage}
                  hasCustomMqttConfig={this.state.hasCustomMqtt}
                  onToggleAttachLicensePlateImage={
                    this.onToggleAttachLicensePlateImage
                  }
                  onDeleteAll={this.onDeleteAllButtonClick}
                />
              </div>
              <div className="bx--col-sm-12 bx--col-md-12 bx--col-lg-12 scc--flex--column scc--no-padding">
                <div className="scc--padding-right">
                  <DrawCanvas
                    key={'canvas-' + streamId + 'contextual'}
                    boxId={boxId}
                    streamId={streamId}
                    frame={cameraFrame}
                    eventTriggers={eventTriggers}
                    highlightedEventTrigger={highlightedEventTrigger}
                    selectedEventTrigger={selectedEventTrigger}
                    onDragEnd={this.onDragEnd}
                    onSelectEventTrigger={this.onSelectEventTrigger}
                    hiddenEventTriggers={this.state.hiddenEventTriggers}
                    isEditable={true}
                    isLoading={isLoadingCameraFrame}
                    onRefreshButtonClick={(event) => {
                      loadCameraFrame(
                        boxId,
                        streamId,
                        true,
                        event.calibration,
                        event.timestamp
                      )
                    }}
                    toggleHideAllEventTriggers={
                      this.toggleAllHiddenEventTriggers
                    }
                    calibration={this.state.calibration}
                    recordTrackCalibration={
                      this.props.stream &&
                      this.props.stream.recordTrackCalibration
                    }
                    setCalibration={(value) => {
                      this.setState({
                        calibration: value
                      })
                    }}
                    disabled={false} // page is only reachable for enabled streams
                    showOnvifButton={true}
                    enableOnvifButton={
                      this.props.stream && this.props.stream.onvifAvailable
                    }
                  />
                  <FormGroup
                    legendText={t('draw.dataTable.title')}
                    className="scc--device-config--title"
                  >
                    <em className="scc--required--hint">
                      {t('draw.dataTable.headline')}
                    </em>
                    <FormLabelWithTooltip id="draw.dataTable.hint" />
                    <FocusAreaTable
                      eventTriggers={eventTriggers}
                      selectedEventTrigger={selectedEventTrigger}
                      hiddenEventTriggers={this.state.hiddenEventTriggers}
                      onToggleHideEventTrigger={this.toggleHiddenEventTrigger}
                      onClickRow={this.onSelectEventTrigger}
                      onDelete={this.onDeleteButtonClick}
                    />

                    <DrawContextTable
                      eventTriggers={eventTriggers}
                      rules={eventRules}
                      selectedEventTrigger={selectedEventTrigger}
                      hiddenEventTriggers={this.state.hiddenEventTriggers}
                      onToggleHideEventTrigger={this.toggleHiddenEventTrigger}
                      onClickRow={this.onSelectEventTrigger}
                      onDelete={this.onDeleteButtonClick}
                      emptyTableText={this.emptyTableText}
                    />
                    <CopyPaste
                      copyType={ECopyPasteType.EVENT_TRIGGER}
                      copyData={eventTriggers}
                      onPasteCallback={this.onPasteEventTrigger}
                      id={'draw.dataTable.copyPaste'}
                    />
                  </FormGroup>
                  <Divider />
                  <RuleEngine
                    eventTriggers={eventTriggers}
                    selectedEventTrigger={selectedEventTrigger}
                    stream={this.props.stream}
                    rules={eventRules}
                    userTemplates={userTemplates}
                    rerender={this.rerender}
                    saveRule={(rule) => {
                      this.props.saveStreamEventRule(boxId, streamId, rule)
                    }}
                    deleteRule={(rule) => {
                      this.props.deleteStreamEventRule(boxId, streamId, rule)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>

        <ConfirmationDialog
          modalHeading={t('draw.confirmation.header')}
          primaryButtonText={t('modal.configuration.primaryButton.title')}
          secondaryButtonText={t('modal.configuration.secondaryButton.title')}
          onRequestClose={this.onCloseDialog}
          onSecondarySubmit={this.onCloseDialog}
          onRequestSubmit={this.onSaveStreamConfig}
          open={this.state.isSaveConfirmationDialogVisible}
        >
          {t('draw.confirmation.text')}
        </ConfirmationDialog>
        <DeleteConfirmationDialog
          primaryButtonText={
            this.state.showDeleteAllWarning
              ? t('modal.delete.primaryButton.reset')
              : null
          }
          onRequestSubmit={(event) => {
            this.state.showDeleteAllWarning
              ? this.onDeleteAll(boxId, streamId, event)
              : this.onDeleteTrigger(boxId, streamId, event)
          }}
          customText={
            this.state.showDeleteAllWarning ? t('modal.delete.resetText') : null
          }
          onSecondarySubmit={this.onCloseDialog}
          onRequestClose={this.onCloseDialog}
          open={this.state.isDeleteConfirmationDialogVisible}
          additionalText={t('draw.ruleEngine.deleteTriggerWarning')}
          showAdditionalText={this.state.showDeleteRuleWarning}
        />
        <ConfirmationDialog
          modalHeading={t('draw.modelChange.header')}
          primaryButtonText={t('draw.modelChange.button')}
          onRequestSubmit={this.onCloseDialog}
          onRequestClose={this.onCloseDialog}
          open={this.state.isInvalidModelErrorVisible}
        >
          {t('draw.modelChange.text')}
        </ConfirmationDialog>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let currentStream = state.streams.byIds[ownProps.match.params.streamId]
  let currentBox = state.boxes.byIds[ownProps.match.params.id]
  let deviceType: DeviceType = DeviceType.UNSPECIFIED
  let streamDetails = state.streamDetails.byIds[ownProps.match.params.streamId]

  let sceneId: string | undefined
  let searchParam = new URLSearchParams(ownProps.location.search)
  let sceneParam = searchParam.has('sceneId') && searchParam.get('sceneId')
  if (sceneParam && uuid.isUuid(sceneParam)) {
    sceneId = sceneParam
  }

  if (currentBox) {
    deviceType = currentBox.type
  }
  return {
    eventTriggers: state.eventTriggers.allIds.map(
      (id) => state.eventTriggers.byIds[id]
    ),
    roiInterval: state.eventTriggers.roiInterval,
    //Note: we could rewrite eventTriggers to an object and filter explititly here? atm reading slices it correctly
    eventRules: state.eventRules.allIds.map((id) => state.eventRules.byIds[id]),
    userTemplates: state.eventRules.templates,
    deviceType: deviceType,
    stream: currentStream,
    streamDetails: streamDetails,
    sceneId: sceneId,

    selectedEventTrigger: state.eventTriggers.selectedEventTrigger,
    highlightedEventTrigger: state.eventTriggers.highlightedEventTrigger,
    isSubmitting:
      state.eventTriggers.isSubmitting || state.eventRules.isSubmitting,
    cameraFrame: state.cameraFrames.byIds[ownProps.match.params.streamId],
    isLoadingCameraFrame:
      state.cameraFrames.loadingIds[ownProps.match.params.streamId],
    currentBox: currentBox,
    currentStream: currentStream
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadCameraFrame,
    loadStreamEventTriggers,
    loadStreamEventRules,
    loadStreamEventRulesTemplates,
    loadStreamDetails,
    addBoxEventTrigger,
    updateStreamEventTrigger,
    deleteStreamEventTrigger,
    deleteStreamEventRule,
    resetStreamEventTriggers,
    setSelectedStreamEventTrigger,
    setHighlightedStreamEventTrigger,
    saveStreamRoiInterval,
    saveStreamEventTriggers,
    saveStreamEventRules,
    saveStreamEventRule,
    loadBoxStreams,
    loadBoxes,
    saveStream,
    resetCameraFrame,
    saveStreamDetails,
    showErrorMessage
  })(withTranslation()(StreamContextualPage))
)

const getRotationDegreeBetween = (pt1, pt2, offset = 90) => {
  return (Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x) * 180) / Math.PI - offset
}

const positiveModulo = (n, m) => {
  return ((n % m) + m) % m
}

const angleSmaller180Degree = (line1, line2) => {
  return (
    positiveModulo(
      getRotationDegreeBetween(line1[0], line1[1], 0) -
        getRotationDegreeBetween(
          {
            x: (line1[0].x + line1[1].x) / 2,
            y: (line1[0].y + line1[1].y) / 2
          },
          {
            x: (line2[0].x + line2[1].x) / 2,
            y: (line2[0].y + line2[1].y) / 2
          },
          0
        ),
      360
    ) <= 180
  )
}

const getParallelLine = (eventTrigger) => {
  const coordinates1 = eventTrigger.coordinates[0]
  const coordinates2 = eventTrigger.coordinates[1]
  const dx = coordinates2.x - coordinates1.x
  const dy = coordinates2.y - coordinates1.y
  const length = Math.sqrt(dx * dx + dy * dy)
  let point1x, point1y, point2x, point2y
  if (
    angleSmaller180Degree(eventTrigger.coordinates, [
      {
        x: (coordinates1.x + coordinates2.x) / 2,
        y: (coordinates1.x + coordinates2.x) / 2
      },
      { x: 0.5, y: 0.5 }
    ])
  ) {
    point1x = coordinates1.x - (dy / length) * 0.2
    point1y = coordinates1.y + (dx / length) * 0.2
  } else {
    point1x = coordinates1.x + (dy / length) * 0.2
    point1y = coordinates1.y - (dx / length) * 0.2
  }
  point2x = point1x + dx
  point2y = point1y + dy
  return { point1x, point1y, point2x, point2y }
}

export interface IContextualHeaderProps {
  t: any
  box: IBox
  stream: IStream
}

const HeaderComponent: React.FC<IContextualHeaderProps> = ({
  t,
  box,
  stream
}) => {
  const boxHasMetadata = box.metadata && Object.entries(box.metadata).length > 0
  const scheduleEnabled = useBoxActionScheduleState(box.id).boxActionSchedule
    ?.enabled

  return (
    <Row
      className="scc--boxcontextual-container--header"
      style={{ paddingLeft: '0', paddingRight: '0' }}
    >
      <Col span={boxHasMetadata ? 7 : 10}>
        <h2 className="scc--boxdetail__header--boxname">
          {box.name && box.name.length
            ? box.name
            : t('configuration.group.device.deviceNamePlaceholder')}
        </h2>
        <div className="scc--boxdetail__header--boxId">
          {getIdComponent(box.id, 'configuration.group.device.deviceId', t)}
        </div>
        {box.serial && (
          <div className="scc--boxdetail__header--serial">
            {getIdComponent(
              box.serial!.toString(),
              'configuration.group.device.deviceSerial',
              t
            )}
          </div>
        )}
        {scheduleEnabled && (
          <Tag className={'scc--boxdetail__header--tag'} color="blue">
            {t('configuration.group.device.scheduleTag')}
          </Tag>
        )}
        {box.updateStatus === EBoxUpdateStatus.UPDATE_AVAILABLE && (
          <Tooltip
            title={t('configuration.group.device.updateAvailableToolTip')}
          >
            <Tag className={'scc--boxdetail__header--tag'} color="red">
              {t('configuration.group.device.updateAvailableTag')}
            </Tag>
          </Tooltip>
        )}
        {box.updateStatus === EBoxUpdateStatus.UPDATE_SCHEDULED && (
          <Tooltip
            title={t('configuration.group.device.updateScheduledToolTip')}
          >
            <Tag className={'scc--boxdetail__header--tag'} color="blue">
              {t('configuration.group.device.updateScheduledTag')}
            </Tag>
          </Tooltip>
        )}
      </Col>
      <Divider type="vertical" />
      <Col span={boxHasMetadata ? 7 : 10}>
        <h2 className="scc--boxdetail__header--streamname">
          {stream.name && stream.name.length
            ? stream.name
            : t('configuration.group.stream.streamname.placeholder')}
        </h2>
        <div className="scc--boxdetail__header--boxId">
          {getIdComponent(stream.id, 'configuration.group.stream.streamId', t)}
        </div>
      </Col>
      <Divider type="vertical" />
      {boxHasMetadata && (
        <>
          <Col span={6}>
            <h2 className="scc--boxdetail__header--metadata-title">
              {t('configuration.group.device.metadata.title')}
            </h2>
            {Object.entries(box.metadata!)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([key, value]) => {
                return (
                  <Tag key={box.id + 'metadata' + key}>
                    <span>
                      {clipLongText(key, 15)}: {clipLongText(value, 20)}
                    </span>
                    <Button
                      icon={<CopyOutlined />}
                      className="scc--boxdetail--copy-id-serial-button"
                      onClick={() => {
                        copy(value)
                      }}
                    />
                  </Tag>
                )
              })}
          </Col>
          <Divider type="vertical" />
        </>
      )}
      <Col span={3} className="scc--boxcontextual-container--statuscell">
        <OperationalStatusCell
          runtimeState={
            stream.streamStatus && stream.streamStatus.state
              ? stream.streamStatus.state
              : ERuntimeState.unknown
          }
          key={stream.streamStatus?.state}
          errorReason={
            stream.streamStatus &&
            (stream.streamStatus.state === 'NOT_OPERATIONAL' ||
              stream.streamStatus.state === 'WARNING')
              ? stream.streamStatus.errorReason
              : EErrorReason.unknown
          }
        />
      </Col>
    </Row>
  )
}
