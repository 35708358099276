import {
  EEventTriggerUtil,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import React from 'react'

import Spacer from '../Spacer'
import { useTranslation } from 'react-i18next'
import { Utils as QbUtils } from 'react-awesome-query-builder'
import { Button, Input, Space, Table } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  TagFilled,
  TagOutlined
} from '@ant-design/icons'
import { ITriggerRule } from '../../types/triggerRule'
import { getConfig } from './config'

interface IRuleTableProps {
  rules: ITriggerRule[]
  selectedEventTrigger: string
  eventTriggers: ICoordinateBasedEventTrigger[]
  editRule: Function
  deleteRule: Function
  toggleTemplate: Function
}

const RuleTable: React.FC<IRuleTableProps> = ({
  eventTriggers,
  selectedEventTrigger,
  rules,
  editRule,
  deleteRule,
  toggleTemplate
}) => {
  const { t } = useTranslation()

  let searchInput: Input | null = null
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const columns = [
    {
      key: 'name',
      title: t('draw.ruleEngine.dataTable.headers.name'),
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      key: 'trigger',
      title: t('draw.ruleEngine.dataTable.headers.trigger'),
      dataIndex: 'triggerNames',
      ...getColumnSearchProps('trigger'),
      sorter: (a, b) => {
        // compare alphabetic first values
        let nameA = a.triggerNames
          .filter((element) => element !== ', ')
          .sort()[0]
        let nameB = b.triggerNames
          .filter((element) => element !== ', ')
          .sort()[0]
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      key: 'rule',
      title: t('draw.ruleEngine.dataTable.headers.rule'),
      dataIndex: 'query'
    },
    {
      key: 'action',
      title: t('draw.ruleEngine.dataTable.headers.action'),
      dataIndex: 'action',
      width: '15%'
    }
  ]

  const dataSource: any = []
  rules.forEach((rule) => {
    let queryStrings: string[] = []
    let triggerNames: string[] = []
    let triggerIds: string[] = []
    let globalTriggers: string[] = []
    for (let condition of rule.conditions) {
      if (condition.trigger) {
        let matchingTrigger = eventTriggers.find((trigger) => {
          return condition.trigger === trigger.localId
        })
        if (matchingTrigger) {
          triggerIds.push(matchingTrigger.localId)
          triggerNames.push(
            matchingTrigger
              ? matchingTrigger.name ||
                  t('draw.dataTable.data.name.undefined') +
                    ' ' +
                    matchingTrigger.typeShort
              : ''
          )
        }
      } else {
        globalTriggers.push(condition.triggerType)
        triggerNames.push(
          'Global ' +
            EEventTriggerUtil.toString(condition.triggerType) +
            ' rule'
        )
      }
      let config = getConfig(
        t,
        condition.trigger,
        condition.triggerType,
        eventTriggers
      )
      let query = QbUtils.loadFromJsonLogic(JSON.parse(condition.query), config)
      if (query) {
        queryStrings.push(QbUtils.queryString(query, config, true)!)
      }
    }

    dataSource.push({
      key: rule.localId,
      name: rule.name,
      triggerNames: triggerNames.flatMap((value, index, array) =>
        array.length - 1 !== index // check for the last item
          ? [value, ', ']
          : value
      ),
      triggerIds: triggerIds,
      globalTriggers: globalTriggers,
      query: queryStrings.flatMap((value, index, array) =>
        array.length - 1 !== index // check for the last item
          ? [value, ' AND ']
          : value
      ),
      action: (
        <>
          {rule.id && !rule.hasChanged && (
            <Button
              className="scc--table--action"
              icon={rule.template ? <TagFilled /> : <TagOutlined />}
              title={t(
                `draw.dataTable.actions.template${
                  rule.template ? 'Remove' : 'Add'
                }`
              )}
              onClick={() => {
                toggleTemplate(rule)
              }}
            />
          )}
          <Spacer width={5} />
          <Button
            className="scc--table--action"
            title={t('draw.dataTable.actions.edit')}
            icon={<EditOutlined />}
            onClick={() => {
              editRule(rule)
            }}
          />
          <Spacer width={5} />
          <Button
            className="scc--table--action"
            icon={<DeleteOutlined />}
            title={t('draw.dataTable.actions.delete')}
            onClick={() => {
              deleteRule(rule)
            }}
          />
        </>
      )
    })
  })

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      locale={{
        emptyText: t('draw.ruleEngine.dataTable.empty')
      }}
      rowClassName={(record, index) =>
        record.triggerIds.includes(selectedEventTrigger) ||
        eventTriggers.find((trigger) => {
          return (
            record.globalTriggers.includes(trigger.objectType) &&
            trigger.localId === selectedEventTrigger
          )
        })
          ? 'scc--tablerow scc--selected'
          : 'scc--tablerow'
      }
    />
  )
}

export default RuleTable
