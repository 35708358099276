import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Select, Space } from 'antd'
import { SplitCellsOutlined } from '@ant-design/icons'
import { TCubeDimension } from '@cubejs-client/core'

interface ISegmentationFilter {
  segmentationDimensions: TCubeDimension[]
  dimensions: any
  handleDimensionChange: any
}

const SegmentationFilter: React.FC<ISegmentationFilter> = (props) => {
  const { t } = useTranslation()
  const { Option } = Select

  let displayDimensions = props.dimensions
    ? props.dimensions.filter((dimension) => {
        return props.segmentationDimensions.find((item) => {
          return item.name === dimension
        })
      })
    : []

  return props.segmentationDimensions.length ? (
    <Row>
      <Space direction="horizontal">
        <SplitCellsOutlined />
        {t(`solutions.scenes.overview.add.data.split`)}
        <Select
          value={displayDimensions}
          optionFilterProp="label"
          mode="multiple"
          allowClear
          style={{ minWidth: '300px', width: '100%' }}
          placeholder={t(
            'solutions.scenes.overview.add.data.selectionPlaceholder'
          )}
          onChange={props.handleDimensionChange}
        >
          {props.segmentationDimensions.map((dimension) => (
            <Option
              key={dimension.name}
              label={dimension.title}
              value={dimension.name}
            >
              {dimension.title}
            </Option>
          ))}
        </Select>
      </Space>
    </Row>
  ) : (
    <></>
  )
}

export default SegmentationFilter
