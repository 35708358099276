import React from 'react'
import * as PropTypes from 'prop-types'
import { Select } from 'antd'
import MemberDropdown from './MemberDropdown'
import RemoveButtonGroup from './RemoveButtonGroup'
import FilterInput from './FilterInput'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useCubeQuery } from '@cubejs-client/react'

const filterCache = {}

function CachedFilterSuggestions(dimension) {
  if (dimension.name in filterCache) {
    return filterCache[dimension.name]
  }
  return FilterSuggestions(dimension)
}

function FilterSuggestions(dimension) {
  const renderProps = useCubeQuery({
    dimensions: [dimension.name]
  })

  let results: { value: string; label: string }[] = []
  if (renderProps.isLoading || renderProps.error || !renderProps.resultSet) {
    return results
  }

  renderProps.resultSet.tablePivot().forEach((item) => {
    let result = item[dimension.name]
    if (!result) {
      return
    }

    result = result.toString()
    results.push({
      value: result,
      label: result
    })
  })
  filterCache[dimension.name] = results
  return results
}

const FilterGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  t
}) => (
  <span>
    {members.map((m) => (
      <div
        style={{
          marginBottom: 12
        }}
        key={m.index}
      >
        <RemoveButtonGroup onRemoveClick={() => updateMethods.remove(m)}>
          <MemberDropdown
            onClick={(updateWith) =>
              updateMethods.update(m, { ...m, dimension: updateWith })
            }
            availableMembers={availableMembers}
            style={{
              width: 150,
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {m.dimension.title}
          </MemberDropdown>
        </RemoveButtonGroup>
        <Select
          value={m.operator}
          onChange={(operator) => updateMethods.update(m, { ...m, operator })}
          style={{
            width: 200,
            marginRight: 8
          }}
        >
          {m.operators.map((operator) => (
            <Select.Option key={operator.name} value={operator.name}>
              {t(
                'solutions.scenes.overview.add.data.filterOperator.' +
                  operator.name
              )}
            </Select.Option>
          ))}
        </Select>
        <FilterInput
          member={m}
          key="filterInput"
          updateMethods={updateMethods}
          optionsMethod={CachedFilterSuggestions}
        />
      </div>
    ))}
    <MemberDropdown
      onClick={(m) =>
        updateMethods.add({
          dimension: m
        })
      }
      availableMembers={availableMembers}
      type="dashed"
      icon={<PlusCircleOutlined />}
    >
      {addMemberName}
    </MemberDropdown>
  </span>
)

FilterGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired
}
export default FilterGroup
