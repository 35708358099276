import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Dropdown, Menu, Space } from 'antd'
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  Query,
  Utils as QbUtils
} from 'react-awesome-query-builder'

import 'react-awesome-query-builder/lib/css/styles.css'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../../types/eventTrigger'
import { DownOutlined } from '@ant-design/icons'
import { getConfig } from '../config'
import { getDefaultQuery, RuleTemplates, RuleTemplateTypes } from '../templates'
import { ITriggerRuleTemplate } from '../../../types/triggerRule'
import RuleTriggerSelection from '../Helper/RuleTriggerSelection'
import FormLabelWithTooltip from '../../FormLabelWithToolTip'
import {
  getCountingLines,
  getRegionsOfInterest,
  getVirtualDoors
} from '../index'

export interface ITriggerRuleQueryProps {
  eventTriggers: ICoordinateBasedEventTrigger[]
  userTemplates: ITriggerRuleTemplate[]
  triggerSelection?: ITriggerSelection
  setTriggerSelection: Function
  setQueryState: Function
  queryState?: State
  invalidTriggerSelection: boolean
  setInvalidTriggerSelection: Function
  emptyQuery: boolean
  setEmptyQuery: Function
  validTriggerTypes: EEventTriggerType[]
}

export interface State {
  tree: ImmutableTree
  config: Config
}

export interface ITriggerSelection {
  triggerType: EEventTriggerType
  trigger?: string
}

export const getTreeFromJsonLogic = (query, config) => {
  let tree = QbUtils.loadFromJsonLogic(JSON.parse(query), config)
  if (!tree) {
    console.error(`RuleEngine: Failed to parse JSON logic\n${query}`)
    throw Error(`RuleEngine: Failed to parse JSON logic\n${query}`)
  }
  return tree
}

const TriggerRuleQuery: React.FC<ITriggerRuleQueryProps> = ({
  eventTriggers,
  userTemplates,
  triggerSelection,
  setTriggerSelection,
  queryState,
  setQueryState,
  invalidTriggerSelection,
  setInvalidTriggerSelection,
  emptyQuery,
  setEmptyQuery,
  validTriggerTypes
}) => {
  const { t } = useTranslation()
  const [clState, setClState] = useState<State>()
  const [roiState, setRoiState] = useState<State>()
  const [odState, setODState] = useState<State>()
  const [vdState, setVDState] = useState<State>()

  const getTriggerType = useCallback(
    (trigger: string) => {
      let countingLines = getCountingLines(eventTriggers)
      let virtualDoors = getVirtualDoors(eventTriggers)
      let regionsOfInterest = getRegionsOfInterest(eventTriggers)
      if (
        countingLines.filter((line) => {
          return line.localId === trigger
        }).length
      ) {
        return EEventTriggerType.crossingLine
      } else if (
        virtualDoors.filter((door) => {
          return door.localId === trigger
        }).length
      ) {
        return EEventTriggerType.virtualDoor
      } else if (
        regionsOfInterest.filter((region) => {
          return region.localId === trigger
        }).length
      ) {
        return EEventTriggerType.regionOfInterest
      }
      console.error('RuleEngine: Trigger ' + trigger + ' has no trigger type')
      throw Error('RuleEngine: Trigger ' + trigger + ' has no trigger type')
    },
    [eventTriggers]
  )

  useEffect(() => {
    if (!queryState || !triggerSelection) {
      return
    }
    let triggerType = triggerSelection!.triggerType
    if (triggerType === EEventTriggerType.crossingLine) {
      setClState(queryState)
    } else if (triggerType === EEventTriggerType.originDestinationZone) {
      setODState(queryState)
    } else if (triggerType === EEventTriggerType.regionOfInterest) {
      setRoiState(queryState)
    } else if (triggerType === EEventTriggerType.virtualDoor) {
      setVDState(queryState)
    }
  }, [triggerSelection, queryState])

  const onChange = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      setQueryState({
        tree: immutableTree,
        config: config
      })
      setEmptyQuery(false)
    },
    [setQueryState, setEmptyQuery]
  )

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container" style={{ padding: '10px' }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  )

  const handleNewTriggerFilterChange = (data: string | undefined) => {
    setInvalidTriggerSelection(!data || !data.length)
    if (!data) {
      setTriggerSelection(undefined)
      return
    }

    // because both options (trigger specific and global trigger rules) need to be selectable in same select-item
    // we need to trick here a bit and treat "data" a bit different depending on if it is an ID ( => trigger-specific)
    // or an EEventTriggerType (global trigger)
    const isGlobalTrigger = data in EEventTriggerType
    const newTriggerType = isGlobalTrigger
      ? (data as EEventTriggerType)
      : getTriggerType(data)
    const newTrigger = isGlobalTrigger ? undefined : data
    const oldTriggerType = triggerSelection?.triggerType
    if (newTriggerType !== oldTriggerType) {
      let config = getConfig(t, newTrigger, newTriggerType, eventTriggers)
      let defaultQuery = getDefaultQuery(
        t,
        newTrigger,
        newTriggerType,
        eventTriggers
      )

      setQueryState({
        tree: defaultQuery,
        config: config
      })
    }
    setTriggerSelection({
      trigger: newTrigger,
      triggerType: newTriggerType
    })
  }

  function getQueryBuilderState() {
    switch (triggerSelection?.triggerType) {
      case EEventTriggerType.crossingLine:
        return clState
      case EEventTriggerType.originDestinationZone:
        return odState
      case EEventTriggerType.virtualDoor:
        return vdState
      case EEventTriggerType.regionOfInterest:
        return roiState
    }
  }

  function handleTemplateChange(item) {
    let config = getConfig(
      t,
      triggerSelection!.trigger,
      triggerSelection!.triggerType,
      eventTriggers
    )

    let state = getQueryBuilderState()
    setQueryState({
      ...state,
      tree: QbUtils.loadTree(
        item === null
          ? getDefaultQuery(
              t,
              triggerSelection!.trigger,
              triggerSelection!.triggerType,
              eventTriggers
            )
          : RuleTemplates[item]
      ),
      config: config
    })
  }

  function handleMenuClick(e) {
    let template = userTemplates.find((element) => {
      return element.id === e.key
    })!
    let condition = template.conditions.find((condition) => {
      return condition.triggerType === triggerSelection!.triggerType
    })
    if (!condition) {
      return
    }
    let config = getConfig(
      t,
      condition.trigger,
      condition.triggerType,
      eventTriggers
    )

    let tree = getTreeFromJsonLogic(condition.query, config)
    let state = getQueryBuilderState()
    template &&
      setQueryState({
        ...state,
        tree: tree,
        config: config
      })
  }

  const hasUserTemplates =
    triggerSelection &&
    userTemplates.find((template) => {
      return template.conditions.find((condition) => {
        return condition.triggerType === triggerSelection!.triggerType
      })
    })

  const templateMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={'userTemplatesMenuLegend'} disabled={true}>
        <span className="rule-engine--template">
          {t('draw.ruleEngine.templates.addLegendRule')}
          {t('draw.ruleEngine.templates.addLegendCamera')}
        </span>
      </Menu.Item>
      {userTemplates
        .filter((template) => {
          return (
            triggerSelection?.triggerType &&
            template.conditions.find(
              (condition) =>
                condition.triggerType === triggerSelection!.triggerType
            )
          )
        })
        .map((template) => {
          return (
            <Menu.Item key={template.id}>
              <span className="rule-engine--template">{template.name}</span>
              &nbsp;-{' '}
              {template.streamName ||
                t('configuration.group.stream.streamname.placeholder')}
            </Menu.Item>
          )
        })}
    </Menu>
  )

  const getQueryBuilderComponent = (triggerType: EEventTriggerType) => {
    let state = getQueryBuilderState()
    return (
      state && (
        <Query
          {...state.config}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
          key={triggerType}
        />
      )
    )
  }

  return (
    <>
      <Card
        headStyle={{ backgroundColor: 'rgba(235, 235, 235, 1)' }}
        title={t('draw.ruleEngine.add.conditionTitle')}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{ width: '100%', marginBottom: '-20px' }}
        >
          <p>{t('draw.ruleEngine.add.eventTrigger')}</p>
          <RuleTriggerSelection
            eventTriggers={eventTriggers}
            newEventTriggers={triggerSelection}
            handleEventTriggerChange={handleNewTriggerFilterChange}
            invalid={invalidTriggerSelection}
            validTriggerTypes={validTriggerTypes}
          />
          {triggerSelection && !!triggerSelection.triggerType && (
            <>
              <p>{t('draw.ruleEngine.add.fromTemplate')}</p>
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleTemplateChange(null)}
                >
                  {t(`draw.ruleEngine.templates.custom`)}
                </Button>
                {hasUserTemplates && (
                  <Dropdown overlay={templateMenu}>
                    <Button>
                      {t(`draw.ruleEngine.templates.userTemplates`)}&nbsp;
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
                {RuleTemplateTypes.filter((templateType) => {
                  return (
                    triggerSelection.triggerType &&
                    templateType.triggerType === triggerSelection.triggerType
                  )
                }).map((templateType) => {
                  return (
                    <Button
                      key={triggerSelection.triggerType}
                      onClick={() => handleTemplateChange(templateType.type)}
                    >
                      {t(`draw.ruleEngine.templates.${templateType.type}`)}
                    </Button>
                  )
                })}
              </Space>
              <div className={emptyQuery ? 'empty-rule-query' : ''}>
                <div>
                  <p>{t('draw.ruleEngine.add.rules')}&nbsp;</p>
                  <FormLabelWithTooltip id={'draw.ruleEngine.defineRules'} />
                </div>

                {triggerSelection &&
                  getQueryBuilderComponent(triggerSelection!.triggerType)}
              </div>
            </>
          )}{' '}
        </Space>
      </Card>
    </>
  )
}

export default TriggerRuleQuery
