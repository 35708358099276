import './SceneDetailPage.scss'

import { NavLink, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { CloseOutline24 } from '@carbon/icons-react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { IScene } from '../../types/scene'
import { Tabs } from 'antd'
import SceneOverview from '../components/SceneOverview'
import SceneStreams from '../components/SceneStreams'
import SceneConfiguration from '../components/SceneConfiguration'
import { DefaultGroupId, IGroup } from '../../types/group'
import { createUpdateScene, deleteScene } from '../../redux/actions/scenes'
import { notify } from '../../services/notify'
import { ESolutionType } from '../../types/solution'
import Scrollbars from 'react-custom-scrollbars-2'
import { streamInfos } from '../../redux/actions/streams'
import { DeleteConfirmationDialog } from '../../components/ConfirmDialog/DeleteConfirmationDialog'

const uuid = require('lodash-uuid')

const { TabPane } = Tabs

interface ISceneDetailPageProps {
  t: any
  history: any
  scene: IScene
  tab: string
  allGroups: IGroup[]
  createUpdateScene: Function
  deleteScene: Function
  streamInfos: Function
}

const DEFAULT_SOLUTION_TYPE = ESolutionType.TRAFFIC
const ESceneTabs = {
  OVERVIEW: 'overview',
  STREAMS: 'streams',
  CONFIGURATION: 'config'
}

class SceneDetailPage extends Component<ISceneDetailPageProps> {
  constructor(props) {
    super(props)
    this.deleteScene = this.deleteScene.bind(this)
    this.onCloseDialog = this.onCloseDialog.bind(this)
    this.saveScene = this.saveScene.bind(this)
    this.props.streamInfos()
  }

  state = {
    isDeleteDialogVisible: false
  }

  onSceneFormSubmit = (data) => {
    const sceneData = Object.assign({}, data)
    sceneData.id = this.props.scene.id
    sceneData.boxStreams = this.props.scene.boxStreams

    sceneData.group.id === 'default' && (sceneData.group = undefined)
    sceneData.solutionType = this.props.scene.new
      ? data.solutionType
      : this.props.scene.solutionType

    sceneData.location && (sceneData.location.timezone = '') // timezone is set by API
    this.saveScene(sceneData)
  }
  onSceneDelete = () => {
    this.setState({
      isDeleteDialogVisible: true
    })
  }

  async saveScene(scene) {
    await this.props.createUpdateScene(scene)
    notify({
      title: this.props.t('notification.configuration.saved.title'),
      message: this.props.t('notification.configuration.saved.message')
    })
  }

  deleteScene() {
    this.props.deleteScene(this.props.scene).then(() => {
      notify({
        title: this.props.t('notification.configuration.saved.title'),
        message: this.props.t('notification.configuration.saved.message')
      })
    })

    this.setState({
      isDeleteDialogVisible: false
    })
    this.props.history.push(`/solutions`)
  }

  onChange = (activeGroup) => {
    this.props.history.push(`#${activeGroup}`)
  }

  onCloseDialog(event) {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }

    this.setState({
      isDeleteDialogVisible: false
    })
  }

  render() {
    const { t, scene } = this.props

    let currentTab = ESceneTabs.OVERVIEW
    if (this.props.tab && Object.values(ESceneTabs).includes(this.props.tab)) {
      currentTab = this.props.tab
    }
    if (scene && scene.new) {
      currentTab = ESceneTabs.CONFIGURATION
    }

    const containerClassNames = scene
      ? 'scc--scenedetail--container shown'
      : 'scc--scenedetail--container'

    return (
      <>
        <div className={containerClassNames}>
          <NavLink to="/solutions" className="scc--close-button">
            <CloseOutline24 className="scc--fill-main" />
          </NavLink>
          <Scrollbars autoHide={true}>
            <h4 className="scc-scenedetail--title">
              {scene && scene.name ? scene.name : '\u00a0'}
            </h4>

            {scene && scene.id && (
              <Tabs
                activeKey={currentTab}
                onChange={this.onChange}
                size="large"
                className="scc--scenedetail--tabs"
                centered
              >
                <TabPane
                  tab={<span>{t('solutions.scenes.overview.title')}</span>}
                  key={ESceneTabs.OVERVIEW}
                  disabled={scene.new}
                >
                  <SceneOverview scene={scene} key={scene && scene.id} />
                </TabPane>
                <TabPane
                  tab={<span>{t('solutions.scenes.streams.title')}</span>}
                  key={ESceneTabs.STREAMS}
                  disabled={scene.new}
                >
                  <SceneStreams scene={scene} saveScene={this.saveScene} />
                </TabPane>
                <TabPane
                  tab={<span>{t('solutions.scenes.config.title')}</span>}
                  key={ESceneTabs.CONFIGURATION}
                >
                  <SceneConfiguration
                    scene={scene}
                    allGroups={this.props.allGroups}
                    onSceneFormSubmit={this.onSceneFormSubmit}
                    onSceneDelete={this.onSceneDelete}
                    key={scene.id + this.props.scene.new}
                  />
                </TabPane>
              </Tabs>
            )}
          </Scrollbars>
        </div>
        <DeleteConfirmationDialog
          onRequestClose={this.onCloseDialog}
          onSecondarySubmit={this.onCloseDialog}
          onRequestSubmit={this.deleteScene}
          open={this.state.isDeleteDialogVisible}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let scene: IScene = state.scenes.byId[ownProps.match.params.id]
  if (!scene && uuid.isUuid(ownProps.match.params.id)) {
    scene = {
      id: ownProps.match.params.id,
      solutionType: DEFAULT_SOLUTION_TYPE,
      boxStreams: [],
      group: {
        id: uuid.isUuid(ownProps.match.params.group)
          ? ownProps.match.params.group
          : DefaultGroupId,
        name: ''
      },
      new: true
    }
  }
  return {
    scene: scene,
    tab: ownProps.location.hash && ownProps.location.hash.replace('#', ''),
    allGroups: state.groups.all
  }
}

export default withRouter(
  connect(mapStateToProps, {
    streamInfos: streamInfos,
    createUpdateScene,
    deleteScene
  })(withTranslation()(SceneDetailPage))
)
