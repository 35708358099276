import React from 'react'
import { Button, Card, Dropdown, Menu } from 'antd'
import {
  AppstoreOutlined,
  BarChartOutlined,
  DribbbleSquareOutlined,
  GoldOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  PieChartOutlined,
  TableOutlined
} from '@ant-design/icons'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const ChartTypes = [
  {
    name: 'table',
    icon: <TableOutlined />
  },
  {
    name: 'number',
    icon: <InfoCircleOutlined />
  },
  {
    name: 'chord',
    icon: <DribbbleSquareOutlined />
  },
  {
    name: 'bar',
    icon: <BarChartOutlined />
  },
  {
    name: 'pie',
    icon: <PieChartOutlined />
  },
  {
    name: 'line',
    icon: <LineChartOutlined />
  },
  {
    name: 'occupancymap',
    icon: <GoldOutlined />
  },
  {
    name: 'heatmap',
    icon: <AppstoreOutlined />
  }
]

interface ISelectChartType {
  availableChartNames?
  chartType
  updateChartType
}

const SelectChartType = (props: ISelectChartType) => {
  const { t } = useTranslation()

  const { availableChartNames, chartType, updateChartType } = props

  const availableCharts = availableChartNames
    ? ChartTypes.filter((item) => {
        return availableChartNames.includes(item.name)
      })
    : ChartTypes
  let foundChartType = availableCharts.find((t) => t.name === chartType)
  if (!foundChartType) {
    foundChartType = availableCharts[0]
  }

  return availableCharts.length > 1 ? (
    <Card title={t('solutions.scenes.overview.add.output.title')}>
      <Dropdown
        overlay={
          <Menu>
            {availableCharts.map((chart) => (
              <Menu.Item
                key={chart.name}
                icon={chart.icon}
                onClick={() => updateChartType(chart.name)}
              >
                {t('solutions.scenes.overview.add.output.types.' + chart.name)}
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomLeft"
        trigger={['click']}
      >
        <Button icon={foundChartType.icon}>
          {t(
            'solutions.scenes.overview.add.output.types.' + foundChartType.name
          )}
        </Button>
      </Dropdown>
    </Card>
  ) : (
    <></>
  )
}

SelectChartType.propTypes = {
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired
}

export default SelectChartType
