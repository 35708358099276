import {
  Alert,
  AutoComplete,
  Button,
  ConfigProvider,
  Empty,
  Input,
  Table
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ReduxStore } from '../../redux/store/configureStore'
import { useSelector } from 'react-redux'
import { IBox } from '../../types/box'
import copy from 'copy-to-clipboard'

interface IMetadataTableProps {
  metadata: Array<{ name: string; value: string }>
  isEdit: boolean
  newMetadata: Array<{ name: string; value: string }>
  setNewMetadata: (newMetadata: Array<{ name: string; value: string }>) => void
  invalidMetadata: boolean
  onEdit: (autoFocus) => void
  autoFocusElement: string
}

export const clipLongText = (text: string | undefined, maxLength) => {
  if (!text) {
    return ''
  }
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + '...'
  }
  return text
}

export const MetadataTable: React.FC<IMetadataTableProps> = (
  props: IMetadataTableProps
) => {
  const {
    metadata,
    isEdit,
    newMetadata,
    setNewMetadata,
    invalidMetadata,
    onEdit,
    autoFocusElement
  } = props

  const [data, setData] = useState<
    Array<{ name: string | JSX.Element; value: string | JSX.Element }>
  >([])

  const [newMetadataKey, setNewMetadataKey] = useState<string>('')

  const [newMetadataLength, setNewMetadataLength] = useState<number>(-1)
  // @ts-ignore
  const allBoxMetadata: Array<string> | undefined = useSelector(
    (state: ReduxStore) =>
      Object.values(state.boxes.byIds)
        // @ts-ignore
        .filter((box: IBox | undefined) => box && box.metadata !== undefined)
        // @ts-ignore
        .map((box: IBox) => Object.keys(box.metadata!))
        .flat(1)
        .sort()
  )

  const { t } = useTranslation()

  const onNameChange = useCallback(
    (newName, index) => {
      let updatedMetadata = Array.from(newMetadata)
      if (newName === '') {
        updatedMetadata.splice(index, 1)
        setNewMetadataKey('')
        if (index !== newMetadataLength - 1) {
          ;(document.activeElement as HTMLElement)?.blur()
        } // prevents focus from being put on other input after it is deleted
      } else if (index === -1) {
        updatedMetadata.push({
          name: newName,
          value: ''
        })
      } else {
        updatedMetadata[index].name = newName
      }
      setNewMetadata(updatedMetadata)
    },
    [newMetadata, setNewMetadata, newMetadataLength]
  )

  const onValueChange = useCallback(
    (changeEvent, index) => {
      let updatedMetadata = Array.from(newMetadata)
      updatedMetadata[index].value = changeEvent.target.value
      setNewMetadata(updatedMetadata)
    },
    [newMetadata, setNewMetadata]
  )

  const getMetadataInputFields = (data) => {
    let result = data.map((item, index) => ({
      name: (
        <AutoComplete
          autoFocus={index === 0 && autoFocusElement === 'metadata'}
          filterOption={true}
          onChange={(newName) => onNameChange(newName, index)}
          value={item.name}
          options={allBoxMetadata
            .filter((item, index) => allBoxMetadata.indexOf(item) === index)
            .filter(
              (item) => !data.some((otheritem) => otheritem.name === item)
            )
            .map((box) => ({ value: box }))}
          className={
            invalidMetadata &&
            (item.name.trim() === '' ||
              data.filter((otheritem) => otheritem.name === item.name).length >
                1)
              ? 'scc--boxdetail__header--metadata-invalid'
              : ''
          }
        />
      ),
      value: (
        <Input
          value={item.value}
          onChange={(changeEvent) => onValueChange(changeEvent, index)}
          className={
            invalidMetadata && item.value.trim() === ''
              ? 'scc--boxdetail__header--metadata-invalid'
              : ''
          }
        />
      ),
      delete: (
        <Button onClick={() => onNameChange('', index)}>
          <DeleteOutlined />
        </Button>
      )
    }))
    if (data.length < 5) {
      result.push({
        name: (
          <AutoComplete
            value={newMetadataKey}
            autoFocus={autoFocusElement === 'metadata' && data.length === 0}
            options={allBoxMetadata
              .filter((item, index) => allBoxMetadata.indexOf(item) === index)
              .filter(
                (item) => !data.some((otheritem) => otheritem.name === item)
              )
              .map((box) => ({ value: box }))}
            placeholder="+"
            onChange={(newName) => onNameChange(newName, -1)}
          />
        ),
        value: <></>,
        delete: <></>
      })
    }
    return result
  }

  useEffect(() => {
    if (newMetadataLength === newMetadata.length && isEdit) {
      return
    } else if (isEdit) {
      setData(newMetadata)
      setNewMetadataLength(newMetadata.length)
    } else {
      setData(metadata)
      setNewMetadataLength(-1)
    }
  }, [isEdit, metadata, newMetadata, newMetadataLength])

  return (
    <div className="scc--boxdetail__header--metadata">
      <h2 className="scc--boxdetail__header--metadata-title">
        {t('configuration.group.device.metadata.title')}
        {!isEdit && (
          <Button
            icon={<EditOutlined />}
            onClick={() => onEdit('metadata')}
            className="scc--boxdetail__header--edit-button"
          />
        )}
      </h2>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={t('configuration.group.device.metadata.noMetadata')}
            style={{ textAlign: 'start' }}
            imageStyle={{ display: 'none' }}
          />
        )}
      >
        {invalidMetadata && (
          <Alert
            message={t('configuration.group.device.metadata.invalidText')}
            type="error"
            showIcon
            className={'scc--boxdetail__header--metadata-alert'}
          />
        )}
        <Table
          pagination={false}
          dataSource={isEdit ? getMetadataInputFields(data) : data}
          showHeader={isEdit || data.length > 0}
          columns={[
            {
              className: 'scc--boxdetail__header--metadata-name',
              dataIndex: 'name',
              title: t('configuration.group.device.metadata.name'),
              key: 'name',
              render: (text) =>
                typeof text === 'string' ? clipLongText(text, 25) : text,
              width: '45%'
            },
            {
              className: 'scc--boxdetail__header--metadata-value',
              dataIndex: 'value',
              title: t('configuration.group.device.metadata.value'),
              key: 'value',
              render: (text) =>
                typeof text === 'string' ? (
                  <>
                    {clipLongText(text, 25)}{' '}
                    <Button
                      icon={<CopyOutlined />}
                      className="scc--boxdetail--copy-id-serial-button"
                      onClick={() => {
                        copy(text)
                      }}
                    />
                  </>
                ) : (
                  text
                ),
              width: '45%'
            },
            {
              className: 'scc--boxdetail__header--metadata-delete',
              dataIndex: 'delete',
              key: 'delete',
              width: '10%'
            }
          ]}
        />
      </ConfigProvider>
    </div>
  )
}
