import { Config } from './config'
import axios from 'axios'
import { getAuthorizationHeader, HTTP_METHOD } from '../redux/middleware/api'
import { log } from './log'
import { AuthProvider } from '../provider/AuthProvider'
import {
  BrowserAuthError,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import _ from 'lodash'
import { TenantInfo } from '../types/tenantInfo'

const API_ROOT = Config.API_ROOT

export const TENANT_STORAGE_NAME = 'swarmTenant'

export const request = {
  scopes: Config.isB2C
    ? [Config.AAD_APP_CLIENT_ID]
    : ['openid', 'profile', 'offline_access'],
  authority: Config.AUTHORITY,
  account: AuthProvider.getAllAccounts()[0],
  redirectUri: window.location.origin
}

export interface TokenClaims {
  exp?: number
  aud?: string
  appDisplayName?: string
  tenantInfo?: string
  roles?: string[]
}

interface SubTenantinfo {
  tenant: string
  appDisplayName: string
  roles: string[]
}

const loginRedirect = function (request) {
  return AuthProvider.acquireTokenRedirect(request)
}
// this needs to be wrapped in a closure / once utility function, because we must not call redirect multiple times in parallel,
// even if multiple api calls are in flight and acquireTokenSilent fails for all of them
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
export const triggerRedirectLoginOnce = _.once(loginRedirect)

export interface TokenInformation {
  idToken: string
  tenantInformations?: TenantInfo
}

export const getTokenInformation = async (): Promise<TokenInformation> => {
  const account = AuthProvider.getActiveAccount()

  if (account) {
    try {
      // slightly changed fix from https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206#issuecomment-961505563
      // issue -> idToken can expire before AuthToken but library checks only for auth token expiration
      // checking if idToken expired and if so doing a force refresh
      let idTokenClaims: TokenClaims | undefined = account.idTokenClaims
      let forceRefresh =
        idTokenClaims && !!idTokenClaims.exp
          ? new Date(idTokenClaims.exp * 1000) < new Date()
          : true
      const token = await AuthProvider.acquireTokenSilent({
        ...request,
        forceRefresh: forceRefresh
      })

      return {
        idToken: token.idToken,
        tenantInformations: token.idTokenClaims
          ? getTenantInfoFromToken(token.idTokenClaims)
          : undefined
      }
    } catch (error) {
      if (
        error instanceof InteractionRequiredAuthError ||
        (error instanceof BrowserAuthError &&
          error.errorCode === 'monitor_window_timeout')
      ) {
        await triggerRedirectLoginOnce(request)
      }
    }
  } else {
    //this is a fallback and should never happen
    await triggerRedirectLoginOnce(request)
  }
  //reject the token promise, i.e. we do need a full new login, further api calls will fail
  return Promise.reject()
}

const rawRequest = async ({ ...options }) => {
  let client = axios.create({
    baseURL: API_ROOT,
    method: HTTP_METHOD.GET
  })
  client.defaults.headers.common = {
    ...client.defaults.headers.common,
    ...(await getAuthorizationHeader()).headers
  }
  client.defaults.headers.common.Accept = 'application/json'

  const onSuccess = (response) => response
  const onError = (error) => {
    log.error(error)
    return error
  }

  return client(options).then(onSuccess).catch(onError)
}

export const getTenantInfoFromToken = (tokenClaims: TokenClaims) => {
  if (!tokenClaims.aud) {
    return
  }

  let tenant = localStorage.getItem(TENANT_STORAGE_NAME)
  let mainTenantId = tokenClaims.aud
  let tenantInfo
  if (!tenant || tenant === mainTenantId) {
    tenantInfo = processMainTenant(tokenClaims)
    if (!tenantInfo) {
      switchToFirstSubTenantIfAvailable(tokenClaims)
    }
  } else {
    tenantInfo = processSubTenant(tokenClaims, tenant)
    if (!tenantInfo) {
      switchToMainTenantIfAvailable(tokenClaims) ||
        switchToFirstSubTenantIfAvailable(tokenClaims)
    }
  }
  return tenantInfo
}

const processMainTenant = (tokenClaims: TokenClaims) => {
  if (tokenClaims.roles && tokenClaims.appDisplayName) {
    return new TenantInfo({
      isMainTenant: true,
      tenantName: tokenClaims.appDisplayName,
      tenantId: tokenClaims.aud!,
      userRoles: tokenClaims.roles
    })
  }
  return undefined
}

const switchToFirstSubTenantIfAvailable = (tokenClaims: TokenClaims) => {
  if (tokenClaims.tenantInfo) {
    let subTenantInformations: {
      tenant: string
      appDisplayName: string
      roles: string[]
    }[] = JSON.parse(tokenClaims.tenantInfo)
    let subTenant = subTenantInformations.find((_) => true)
    if (subTenant) {
      // set the tenant in local storage to the first subtenant and reload the page to have clean state
      localStorage.setItem(TENANT_STORAGE_NAME, subTenant.tenant)
      window.location.href = '/'
    }
  }
}

const processSubTenant = (tokenClaims: TokenClaims, tenant: string) => {
  let subTenantInformations = getSubTenants(tokenClaims)
  if (subTenantInformations) {
    let subTenant = subTenantInformations.find((info) => info.tenant === tenant)
    if (subTenant) {
      return new TenantInfo({
        isMainTenant: false,
        tenantName: subTenant.appDisplayName,
        tenantId: subTenant.tenant,
        userRoles: subTenant.roles
      })
    }
  }
  return undefined
}

const getSubTenants = (
  tokenClaims: TokenClaims
): SubTenantinfo[] | undefined => {
  if (tokenClaims.tenantInfo) {
    return JSON.parse(tokenClaims.tenantInfo)
  }
  return undefined
}

const switchToMainTenantIfAvailable = (tokenClaims: TokenClaims) => {
  if (tokenClaims.roles && tokenClaims.appDisplayName) {
    // reset tenant storage ( = main tenant) and reload page to have clean state
    localStorage.removeItem(TENANT_STORAGE_NAME)
    window.location.href = '/'
    return true
  }
  return false
}

export const getAllTenantsFromClaims = (
  tokenClaims: TokenClaims
): TenantInfo[] => {
  if (!tokenClaims.aud) {
    return []
  }
  let result: TenantInfo[] = []
  let main = processMainTenant(tokenClaims)
  if (main) {
    result.push(main)
  }
  let subTenantInformations = getSubTenants(tokenClaims)
  if (subTenantInformations) {
    subTenantInformations.forEach((subTenant) =>
      result.push(
        new TenantInfo({
          isMainTenant: false,
          tenantName: subTenant.appDisplayName,
          tenantId: subTenant.tenant,
          userRoles: subTenant.roles
        })
      )
    )
  }
  return result
}

export const leaveHeaderEmptyOnMissingOrMainTenant = (
  tokenInfo: TokenInformation
) => !tokenInfo.tenantInformations || tokenInfo.tenantInformations.isMainTenant

export default rawRequest
