import {
  AbstractCoordinateBasedEventTrigger,
  EEventTriggerType
} from './eventTrigger'
import { uuid } from 'lodash-uuid'

export class VirtualDoorEventTrigger extends AbstractCoordinateBasedEventTrigger {
  constructor(props) {
    super(props)
    this.objectType = EEventTriggerType.virtualDoor
  }

  public static fromObject(props: {
    [key: string]: any
  }): VirtualDoorEventTrigger {
    const { localId, coordinates, ...otherProps } = props
    return new VirtualDoorEventTrigger({
      localId: localId || uuid(),
      coordinates: coordinates || [
        { x: 0.4, y: 0.3 },
        { x: 0.6, y: 0.3 },
        { x: 0.6, y: 0.6 },
        { x: 0.4, y: 0.6 }
      ],
      ...otherProps
    })
  }
}
