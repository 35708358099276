import AntdConfig from 'react-awesome-query-builder/lib/config/antd'
import { Config, FieldOrGroup } from 'react-awesome-query-builder'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import { mapTriggersForQueryConfig } from './index'

//@ts-ignore
const getInitialConfig = (t: (key: string) => string): Config => ({
  ...AntdConfig,
  // @ts-ignore
  settings: {
    ...AntdConfig.settings,
    valueSourcesPopupTitle: t(
      'draw.ruleEngine.queryBuilder.config.valueSourcesPopupTitle'
    ),
    valueSourcesInfo: {
      field: {
        label: t('draw.ruleEngine.queryBuilder.config.valueSourcesInfo.field')
      },
      value: {
        label: t('draw.ruleEngine.queryBuilder.config.valueSourcesInfo.value')
      }
    },
    addRuleLabel: t('draw.ruleEngine.queryBuilder.config.addRuleLabel'),
    addSubRuleLabel: t('draw.ruleEngine.queryBuilder.config.addSubRuleLabel'),
    fieldPlaceholder: t('draw.ruleEngine.queryBuilder.config.fieldPlaceholder'),
    operatorPlaceholder: t(
      'draw.ruleEngine.queryBuilder.config.operatorPlaceholder'
    ),
    fieldSeparatorDisplay: t(
      'draw.ruleEngine.queryBuilder.config.fieldSeparatorDisplay'
    ),
    canRegroup: false,
    canReorder: false
  },
  // @ts-ignore
  operators: {
    ...AntdConfig.operators,

    // @ts-ignore
    select_equals: {
      ...AntdConfig.operators.select_equals,
      label: t('draw.ruleEngine.queryBuilder.config.operators.select_equals'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.select_equals'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    select_not_equals: {
      ...AntdConfig.operators.select_not_equals,
      label: t(
        'draw.ruleEngine.queryBuilder.config.operators.select_not_equals'
      ),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.select_not_equals'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    select_any_in: {
      ...AntdConfig.operators.select_any_in,
      label: t('draw.ruleEngine.queryBuilder.config.operators.select_any_in'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.select_any_in'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    select_not_any_in: {
      ...AntdConfig.operators.select_not_any_in,
      label: t(
        'draw.ruleEngine.queryBuilder.config.operators.select_not_any_in'
      ),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.select_not_any_in'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    equal: {
      ...AntdConfig.operators.equal,
      label: t('draw.ruleEngine.queryBuilder.config.operators.equal'),
      labelForFormat: t('draw.ruleEngine.queryBuilder.config.operators.equal'),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    not_equal: {
      ...AntdConfig.operators.not_equal,
      label: t('draw.ruleEngine.queryBuilder.config.operators.not_equal'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.not_equal'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    between: {
      ...AntdConfig.operators.between,
      label: t('draw.ruleEngine.queryBuilder.config.operators.between'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.between'
      ),
      textSeparators: [
        '',
        t('draw.ruleEngine.queryBuilder.config.operators.betweenSeparator')
      ],
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    not_between: {
      ...AntdConfig.operators.not_between,
      label: t('draw.ruleEngine.queryBuilder.config.operators.not_between'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.not_between'
      ),
      textSeparators: [
        '',
        t('draw.ruleEngine.queryBuilder.config.operators.betweenSeparator')
      ],
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    like: {
      ...AntdConfig.operators.like,
      label: t('draw.ruleEngine.queryBuilder.config.operators.like'),
      labelForFormat: t('draw.ruleEngine.queryBuilder.config.operators.like'),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    not_like: {
      ...AntdConfig.operators.not_like,
      label: t('draw.ruleEngine.queryBuilder.config.operators.not_like'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.not_like'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    starts_with: {
      ...AntdConfig.operators.starts_with,
      label: t('draw.ruleEngine.queryBuilder.config.operators.starts_with'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.starts_with'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    ends_with: {
      ...AntdConfig.operators.ends_with,
      label: t('draw.ruleEngine.queryBuilder.config.operators.ends_with'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.ends_with'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    is_empty: {
      ...AntdConfig.operators.is_empty,
      label: t('draw.ruleEngine.queryBuilder.config.operators.is_empty'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.is_empty'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    },
    // @ts-ignore
    is_not_empty: {
      ...AntdConfig.operators.is_not_empty,
      label: t('draw.ruleEngine.queryBuilder.config.operators.is_not_empty'),
      labelForFormat: t(
        'draw.ruleEngine.queryBuilder.config.operators.is_not_empty'
      ),
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef && opDef.labelForFormat} ${value}`
    }
  },
  // @ts-ignore
  types: {
    ...AntdConfig.types,
    // @ts-ignore
    text: {
      ...AntdConfig.types.text,
      excludeOperators: ['proximity', 'is_null', 'is_not_null']
    },
    // @ts-ignore
    number: {
      ...AntdConfig.types.number,
      widgets: {
        ...AntdConfig.types.number.widgets,
        slider: {
          operators: ['less', 'less_or_equal', 'greater', 'greater_or_equal'],
          widgetProps: {
            valuePlaceholder: t(
              'draw.ruleEngine.queryBuilder.config.numberPlaceholder'
            )
          }
        }
      },
      defaultOperator: 'greater',
      excludeOperators: [
        'equal',
        'not_equal',
        'proximity',
        'is_null',
        'is_not_null',
        'is_empty',
        'is_not_empty'
      ]
    },
    // @ts-ignore
    select: {
      ...AntdConfig.types.select,
      excludeOperators: ['is_null', 'is_not_null'],
      widgets: {
        ...AntdConfig.types.select.widgets,
        field: {
          operators: ['select_equals', 'select_not_equals']
        },
        select: {
          operators: ['select_equals', 'select_not_equals'],
          widgetProps: {
            valuePlaceholder: t(
              'draw.ruleEngine.queryBuilder.config.valuePlaceholder'
            )
          }
        },
        multiselect: {
          operators: ['select_any_in', 'select_not_any_in'],
          widgetProps: {
            valuePlaceholder: t(
              'draw.ruleEngine.queryBuilder.config.valuesPlaceholder'
            )
          }
        }
      }
    },
    '!group': {
      defaultOperator: 'greater_or_equal',
      mainWidget: 'number',
      widgets: {
        number: {
          widgetProps: {
            min: 0,
            max: 100
          },
          operators: [
            'less',
            'greater',
            'less_or_equal',
            'greater_or_equal',
            'between'
          ],
          opProps: {
            // @ts-ignore
            less: {
              label:
                t('draw.ruleEngine.queryBuilder.config.operators.count') + ' <'
            },
            // @ts-ignore
            less_or_equal: {
              label:
                t('draw.ruleEngine.queryBuilder.config.operators.count') + ' <='
            },
            // @ts-ignore
            greater: {
              label:
                t('draw.ruleEngine.queryBuilder.config.operators.count') + ' >'
            },
            // @ts-ignore
            greater_or_equal: {
              label:
                t('draw.ruleEngine.queryBuilder.config.operators.count') + ' >='
            },
            // @ts-ignore
            between: {
              label: t(
                'draw.ruleEngine.queryBuilder.config.operators.countBetween'
              )
            }
          }
        }
      }
    }
  }
})

const getClasses = (t: (key: string) => string): FieldOrGroup => ({
  label: t('draw.ruleEngine.queryBuilder.config.fields.class'),
  type: 'select',
  valueSources: ['value'],
  operators: [
    'select_equals',
    'select_not_equals',
    'select_any_in',
    'select_not_any_in'
  ],
  fieldSettings: {
    listValues: [
      {
        value: 'car',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.car'
        )
      },
      {
        value: 'truck',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.truck'
        )
      },
      {
        value: 'bus',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.bus'
        )
      },
      {
        value: 'motorbike',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.motorbike'
        )
      },
      {
        value: 'bicycle',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.bicycle'
        )
      },
      {
        value: 'person',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.person'
        )
      },
      {
        value: 'scooter',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.scooter'
        )
      },
      {
        value: 'tram',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.tram'
        )
      },
      {
        value: 'other',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.other'
        )
      }
    ]
  }
})

const getSubclasses = (t: (key: string) => string): FieldOrGroup => ({
  label: t('draw.ruleEngine.queryBuilder.config.fields.subclass'),
  type: 'select',
  valueSources: ['value'],
  operators: [
    'select_equals',
    'select_not_equals',
    'select_any_in',
    'select_not_any_in'
  ],
  fieldSettings: {
    listValues: [
      {
        value: 'van',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.van'
        )
      },
      {
        value: 'car-with-trailer',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.car_with_trailer'
        )
      },
      {
        value: 'single-unit-truck',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.single_unit_truck'
        )
      },
      {
        value: 'truck-with-trailer',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.truck_with_trailer'
        )
      },
      {
        value: 'articulated-truck',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.articulated_truck'
        )
      }
    ]
  }
})

const getDirections = (t: (key: string) => string): FieldOrGroup => ({
  label: t('draw.ruleEngine.queryBuilder.config.fields.direction'),
  type: 'select',
  valueSources: ['value'],
  operators: ['select_equals', 'select_not_equals'],
  fieldSettings: {
    listValues: [
      {
        value: 'in',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.in'
        )
      },
      {
        value: 'out',
        title: t(
          'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.out'
        )
      }
    ]
  }
})

export const getVdConfig = (t: (key: string) => string): Config => ({
  ...getInitialConfig(t),
  fields: {
    vd: {
      label: t('draw.ruleEngine.queryBuilder.config.fields.vd.label'),
      tooltip: t('draw.ruleEngine.queryBuilder.config.fields.vd.tooltip'),
      type: '!struct',
      operators: ['equal'],
      subfields: {
        class: getClasses(t),
        subclass: getSubclasses(t),
        direction: getDirections(t)
      }
    }
  }
})

export const getOdConfig = (
  t: (key: string) => string,
  zones: triggerMapping[]
): Config => ({
  ...getInitialConfig(t),
  fields: {
    od: {
      label: t('draw.ruleEngine.queryBuilder.config.fields.od.label'),
      tooltip: t('draw.ruleEngine.queryBuilder.config.fields.od.tooltip'),
      type: '!struct',
      operators: ['equal'],
      subfields: {
        class: Object.assign({}, getClasses(t), { hideForCompare: true }),
        subclass: Object.assign({}, getSubclasses(t), { hideForCompare: true }),
        entryZoneId: {
          label: t('draw.ruleEngine.queryBuilder.config.fields.od.entryZoneId'),
          type: 'select',
          operators: [
            'select_equals',
            'select_not_equals',
            'select_any_in',
            'select_not_any_in'
          ],
          valueSources: ['value', 'field'],
          fieldSettings: {
            listValues: zones
          }
        },
        exitZoneId: {
          label: t('draw.ruleEngine.queryBuilder.config.fields.od.exitZoneId'),
          type: 'select',
          operators: [
            'select_equals',
            'select_not_equals',
            'select_any_in',
            'select_not_any_in'
          ],
          valueSources: ['value', 'field'],
          fieldSettings: {
            listValues: zones
          }
        }
      }
    }
  }
})

export const getClConfig = (t: (key: string) => string): Config => ({
  ...getInitialConfig(t),
  fields: {
    cl: {
      label: t('draw.ruleEngine.queryBuilder.config.fields.cl.label'),
      tooltip: t('draw.ruleEngine.queryBuilder.config.fields.cl.tooltip'),
      type: '!struct',
      operators: ['equal'],
      subfields: {
        class: getClasses(t),
        subclass: getSubclasses(t),
        direction: getDirections(t),
        speedEstimate: {
          label: t(
            'draw.ruleEngine.queryBuilder.config.fields.cl.speedEstimate'
          ),
          type: 'number',
          operators: [
            'between',
            'not_between',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal'
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
            max: 200
          },
          preferWidgets: ['slider', 'number']
        },
        numberPlate: {
          type: 'text',
          label: t('draw.ruleEngine.queryBuilder.config.fields.cl.numberPlate'),
          operators: [
            'like',
            'not_like',
            'starts_with',
            'ends_with',
            'is_empty',
            'is_not_empty'
          ],
          mainWidgetProps: {
            valuePlaceholder: t(
              'draw.ruleEngine.queryBuilder.config.textPlaceholder'
            )
          },
          fieldSettings: {
            validateValue: (val: string) => {
              return (
                val.length < 10 &&
                (val === '' || val.match(/^[A-Za-z0-9öÖäÄüÜ]{1,11}$/) !== null)
              )
            }
          }
        }
      }
    }
  }
})

export const getRoiConfig = (t: (key: string) => string): Config => ({
  ...getInitialConfig(t),
  fields: {
    roi: {
      label: t('draw.ruleEngine.queryBuilder.config.fields.roi.label'),
      tooltip: t('draw.ruleEngine.queryBuilder.config.fields.roi.tooltip'),
      type: '!struct',
      operators: ['equal'],
      subfields: {
        state: {
          label: t('draw.ruleEngine.queryBuilder.config.fields.roi.state'),
          type: 'select',
          valueSources: ['value'],
          operators: ['select_equals', 'select_not_equals'],
          fieldSettings: {
            listValues: [
              {
                value: 'free',
                title: t(
                  'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.free'
                )
              },
              {
                value: 'occupied',
                title: t(
                  'draw.ruleEngine.queryBuilder.config.fieldSettings.listValues.occupied'
                )
              }
            ]
          }
        },
        objects: {
          label: t(
            'draw.ruleEngine.queryBuilder.config.fields.roi.objects.label'
          ),
          tooltip: t(
            'draw.ruleEngine.queryBuilder.config.fields.roi.objects.tooltip'
          ),
          type: '!group',
          mode: 'array',
          defaultValue: 1,
          subfields: {
            class: getClasses(t),
            subclass: getSubclasses(t),
            dwellTime: {
              label: t(
                'draw.ruleEngine.queryBuilder.config.fields.roi.objects.dwellTime'
              ),
              type: 'number',
              operators: [
                'between',
                'not_between',
                'less',
                'less_or_equal',
                'greater',
                'greater_or_equal'
              ],
              valueSources: ['value'],
              fieldSettings: {
                min: 0,
                max: 600
              },
              preferWidgets: ['slider', 'number']
            }
          }
        }
      }
    }
  }
})

export const getConfig = (
  t: (key: string) => string,
  eventTrigger: string | undefined,
  eventTriggerType: EEventTriggerType | undefined,
  allTriggers: ICoordinateBasedEventTrigger[]
): Config => {
  if (!eventTrigger) {
    return getGlobalConfig(t, eventTriggerType, allTriggers)
  }
  switch (eventTriggerType) {
    case EEventTriggerType.crossingLine:
      return getClConfig(t)
    case EEventTriggerType.virtualDoor:
      return getVdConfig(t)
    case EEventTriggerType.regionOfInterest:
      return getRoiConfig(t)
  }
  console.error(
    'RuleEngine: Failed to load default config for ' + eventTriggerType + 'rule'
  )
  throw Error(
    'RuleEngine: Failed to load default config for ' + eventTrigger + 'rule'
  )
}

const getGlobalConfig = (
  t: (key: string) => string,
  eventTriggerType: EEventTriggerType | undefined,
  allTriggers: ICoordinateBasedEventTrigger[]
): Config => {
  if (eventTriggerType === EEventTriggerType.originDestinationZone) {
    return getOdConfig(
      t,
      mapTriggersForQueryConfig(allTriggers, eventTriggerType)
    )
  }
  console.error(
    'RuleEngine: Failed to load default config for global ' +
      eventTriggerType +
      'rule'
  )
  throw Error(
    'RuleEngine: Failed to load default config for global ' +
      eventTriggerType +
      'rule'
  )
}

export interface triggerMapping {
  value: string
  title: string
}
