import {
  AbstractCoordinateBasedEventTrigger,
  EEventTriggerType
} from './eventTrigger'
import { uuid } from 'lodash-uuid'

export enum ERegionOfInterestTriggerType {
  time = 'time',
  change = 'change',
  rule = 'rule'
}

export enum ERegionOfInterestType {
  generic = 'generic',
  parking = 'parking',
  focus = 'focus'
}

export class RegionOfInterestEventTrigger extends AbstractCoordinateBasedEventTrigger {
  private trigger?: ERegionOfInterestTriggerType
  public roiType: ERegionOfInterestType
  public capacity?: number

  constructor(props) {
    super(props)
    this.objectType = EEventTriggerType.regionOfInterest
    if (props.roiType && props.roiType === ERegionOfInterestType.focus) {
      this.roiType = ERegionOfInterestType.focus
      this.trigger = undefined
      this.capacity = undefined
    } else {
      this.trigger = props.trigger || ERegionOfInterestTriggerType.time
      this.roiType = props.roiType || ERegionOfInterestType.generic
      this.capacity = props.capacity || 1
    }
  }

  /**
   * static constructor for RegionOfInterestEventTrigger
   * can handle props and will provide default values for missing props
   */
  public static fromObject(props: {
    [key: string]: any
  }): RegionOfInterestEventTrigger {
    const {
      localId,
      trigger,
      roiType,
      capacity,
      coordinates,
      ...otherProps
    } = props
    return new RegionOfInterestEventTrigger({
      localId: localId || uuid(),
      trigger: trigger || ERegionOfInterestTriggerType.time,
      roiType: roiType || ERegionOfInterestType.generic,
      capacity: capacity || 1,
      coordinates: coordinates || [
        { x: 0.4, y: 0.3 },
        { x: 0.6, y: 0.3 },
        { x: 0.6, y: 0.6 },
        { x: 0.4, y: 0.6 }
      ],
      ...otherProps
    })
  }
}

export const isFocusArea = (event) =>
  event.objectType === EEventTriggerType.regionOfInterest &&
  (event as RegionOfInterestEventTrigger).roiType ===
    ERegionOfInterestType.focus
