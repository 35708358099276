import App from '../components/App'
import { Provider } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from '../provider/AuthProvider'
import { Config } from '../services/config'
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider
} from '@azure/msal-react'
import { EventType, InteractionType } from '@azure/msal-browser'
import { ConfigProvider } from 'antd'
import { useTranslation } from 'react-i18next'
import { Locale } from 'antd/lib/locale-provider'
import enUS from 'antd/es/locale/en_US'
import deDE from 'antd/es/locale/de_DE'
import moment from 'moment'
import 'moment/locale/de'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false
    }
  }
})

// Default to using the first account if no account is active on page load
if (
  !AuthProvider.getActiveAccount() &&
  AuthProvider.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  AuthProvider.setActiveAccount(AuthProvider.getAllAccounts()[0])
}

AuthProvider.addEventCallback((event) => {
  // @ts-ignore
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // @ts-ignore
    const account = event.payload.account
    AuthProvider.setActiveAccount(account)
  }
})

const MsalWrapper = ({ store }) => {
  const authRequest = {
    scopes: Config.isB2C
      ? [Config.AAD_APP_CLIENT_ID]
      : ['openid', 'profile', 'offline_access'],
    authority: Config.AUTHORITY
  }
  AuthProvider.handleRedirectPromise().then((res) => {
    const account = AuthProvider.getActiveAccount()
    if (!account) {
      //  @ts-ignore
      AuthProvider.loginRedirect(authRequest)
    }
  })

  const { i18n } = useTranslation()
  const [antdLocale, setAntdLocale] = useState<Locale>(enUS)

  useEffect(() => {
    // Set Ant Design locale based on i18n language (ignore regional dialects like de-at)
    const currentLang = i18n.language.split(/[-_]/)[0]
    // Set Ant Design locale based on i18n language
    switch (currentLang) {
      case 'de':
        setAntdLocale(deDE)
        moment.locale('de')
        break
      case 'en':
      default:
        setAntdLocale(enUS)
        moment.locale('en')
        break
    }
  }, [i18n.language])

  return (
    <>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={antdLocale}>
              <App />
            </ConfigProvider>
          </QueryClientProvider>
        </Provider>
      </AuthenticatedTemplate>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      />
    </>
  )
}

const Root = ({ store }) => {
  return (
    <>
      <MsalProvider instance={AuthProvider}>
        <MsalWrapper store={store} />
      </MsalProvider>
    </>
  )
}

export default Root
