import React from 'react'

import { Card, Alert, Button, Space, Tooltip } from 'antd'
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import { useTranslation } from 'react-i18next'
import { ECopyPasteType } from '../../types/copyPaste'

interface ICopyPasteProps {
  copyType: ECopyPasteType
  copyData: any
  id: string
  onPasteCallback: (
    parsedType: ECopyPasteType,
    parsedData: any
  ) => Promise<void>
}

const CopyPaste: React.FC<ICopyPasteProps> = ({
  copyType,
  copyData,
  id,
  onPasteCallback
}) => {
  const { t } = useTranslation()
  const copyLabelText = t(`${id}.copyLabelText`)
  const pasteLabelText = t(`${id}.pasteLabelText`)
  const copyTooltipText = t(`${id}.copyTooltipText`)
  const pasteTooltipText = t(`${id}.pasteTooltipText`)
  const alertPasteError = t(`${id}.alerts.pasteError`)
  const alertCopySuccess = t(`${id}.alerts.copySuccess`)

  const [hasPasteError, setHasPasteError] = React.useState<boolean>(false)
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false)

  const onCopyEventTrigger = async () => {
    try {
      setHasPasteError(false)

      const jsonData = JSON.stringify({
        type: copyType,
        data: copyData
      })
      await navigator.clipboard.writeText(jsonData)

      setCopySuccess(true)
    } catch (error) {
      setCopySuccess(false)
    }
  }

  const onPasteEventTrigger = async () => {
    setCopySuccess(false)

    try {
      const clipboardTextPromise = navigator.clipboard.readText()
      const timeoutPromise = new Promise<string>(() => {
        setTimeout(() => {
          return ''
        }, 1000)
      })

      const clipboardText = await Promise.race([
        clipboardTextPromise,
        timeoutPromise
      ])

      let {
        type: parsedType,
        data: parsedData
      }: { type: ECopyPasteType; data: any } = JSON.parse(clipboardText)

      if (
        !Object.values(ECopyPasteType).includes(parsedType as ECopyPasteType)
      ) {
        throw new TypeError(`Invalid type: ${parsedType}`)
      }

      if (parsedType !== copyType) {
        throw new Error(`Wrong type: ${parsedType}`)
      }

      await onPasteCallback(parsedType, parsedData)

      setHasPasteError(false)
    } catch (error) {
      console.error('Failed to parse clipboard data:', error)
      setHasPasteError(true)
    }
  }

  return (
    <Card size="small">
      <FormLabelWithTooltip id={id} />
      <Space style={{ width: '100%' }}>
        <Tooltip title={copyTooltipText}>
          <Button onClick={onCopyEventTrigger}>
            {copyLabelText}
            <CloudDownloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip title={pasteTooltipText}>
          <Button onClick={onPasteEventTrigger}>
            {pasteLabelText}
            <CloudUploadOutlined />
          </Button>
        </Tooltip>
      </Space>
      {hasPasteError && (
        <Alert
          className={'scc--box-schedule-alert'}
          type={'warning'}
          message={alertPasteError}
        />
      )}
      {copySuccess && (
        <Alert
          className={'scc--box-schedule-alert'}
          type={'success'}
          message={alertCopySuccess}
        />
      )}
    </Card>
  )
}

export default CopyPaste
